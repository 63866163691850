<template>
  <AppHeader :navData="navData" />
  <router-view :specializeData="specializeData" :navData="navData" />
  <AppFooter/>
</template>

<script>
  import AppHeader from "./components/layouts/AppHeader";
  import AppFooter from "./components/layouts/AppFooter";
  import axios from 'axios'
  import swal2 from 'sweetalert2'
  export default {
    name: 'App',
    components: {
      AppHeader , AppFooter
    },
    data(){
      return{navData:[],specializeData:[]}
    },
    methods:{
      getLogo : async  function(){
        const response=await axios.get(this.$apiUrl+"navWebData" ,null)
        if(response.data.status==true){

          this.navData=response.data.data


          console.log(this.navData.logo_light)


        }else{
          swal2.fire("error" ,response.data.message,"error");

        }

      },
      getSpecialize:async function () {
        const response = await axios.get(this.$apiUrl + 'specialize/last-spcializations/all/spec_only',{
          headers: {
            'Accept-Language':this.locale ,
          }
        });
        if (response.data.status == true) {

          this.specializeData= response.data.data


        } else {
          swal2.fire("error", response.data.message, "error");

        }
      }
    },
    mounted:function(){
      this.getLogo()
      this.getSpecialize()
    }


  }
</script>

