<template>
  <!-- Inne Page Banner Area Start Here -->
  <section class="inner-page-banner bg-common inner-page-top-margin" :style="{backgroundImage:'url(\'' +this.$attachUrl+'images/'+this.navData.doctors_banner+ '\')'}">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="breadcrumbs-area">
            <h1 v-if="locale=='en'">All Doctors</h1><h1 v-else> كل الاستشارات</h1>
            <ul>
              <li>
                <a v-if="locale=='en'" href="#">Home</a>
                <a v-else href="#">الرئيسيه</a>
              </li>
              <li v-if="locale=='en'">Consaltations</li>
              <li v-else>الاستشارات</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Inne Page Banner Area End Here -->
  <!-- All Doctors Start Here -->
  <section class="team-wrap-layout2 bg-light-accent100">
    <div class="container">
      <div class="isotope-wrap">
        <div class="text-center">
          <div class="isotope-classes-tab isotop-btn">
            <a  @click.prevent="getConsaltations('all')"  href="#" class="current nav-item"  data-filter="*"> <span v-if="locale=='en'">ALL</span><span v-else>الكل</span></a>
            <a  @click.prevent="getConsaltations('open')" href="#" class="nav-item" data-filter=".open"><span v-if="locale=='en'">Opening</span><span v-else>المفتوحه</span></a>
            <a  @click.prevent="getConsaltations('cancle')" href="#" class="nav-item" data-filter=".cancle"><span v-if="locale=='en'">Cancelled</span><span v-else>الملغيه</span></a>
            <a  @click.prevent="getConsaltations('wait')" href="#" class="nav-item" data-filter=".'wait"><span v-if="locale=='en'">Waiting</span><span v-else>انتظار</span></a>
            <a  @click.prevent="getConsaltations('finish')"  href="#" class="nav-item" data-filter=".finish"><span v-if="locale=='en'">Ended</span><span v-else>منتهيه</span></a>
          </div>


          <div class="row featuredContainer" id="no-equal-gallery">

            <div   v-for="(consaltion,index) in (consaltations)"  :class="consaltion.status" class="no-equal-item col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12"  >
              <router-link :to="{ name: 'consaltation_details', params: {consaltId: consaltion.id }}">
              <div class="team-box-layout2">
                <div class="item-img">

                  <img v-bind:src="consaltion.spacialization.doctor_details.avatar_url" alt="Team1" class="img-fluid rounded-circle">
                  <ul class="item-icon">
                    <li><router-link :to="{ name: 'consaltation_details', params: {consaltId: consaltion.id }}"><i class="fas fa-plus"></i></router-link></li>
                  </ul>
                </div>
                <div class="item-content">
                  <h3 class="item-title"><router-link :to="{ name: 'consaltation_details', params: {consaltId: consaltion.id }}">{{consaltion.spacialization.doctor_details.name}} </router-link></h3>
                  <p>{{consaltion.spacialization.name}}</p>
                </div>
                <div class="item-schedule">
                  <ul class="item-btns">
                    <li class="col-12">
                      <h5 class="header col-3"> date:</h5><span class="col-9">{{consaltion.date}}</span>
                    </li>
                    <li>
                      <h5 class="header col-3"> price:</h5><span class="col-9">{{consaltion.price}}</span>
                    </li>
                  </ul>
                  <h3 class="btn-danger" style="border-radius: inherit !important;">{{consaltion.status}}</h3>
                </div>
              </div>
              </router-link>
            </div>

          </div>
        </div>
      </div>
    </div>
    <Pagination v-if="listItems" :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />
  </section>
  <!-- All Doctors End Here -->
</template>
<script>
  import axios from 'axios'
  import swal2 from 'sweetalert2'
  import Pagination from '../components/Pagination'
  import Loader from '../components/Loader'
  import { mapState } from 'vuex'
  import { mapGetters } from "vuex";

  export default {
    components: {
      Pagination,
      Loader
    },
    name:'myconsaltations',
    props:{navData:Array,specializeData:Array},
    data(){
      return{
        status:'all',
        checkLogin:false,
        special_id:(this.$router.currentRoute._value.query.specialization_id>0)?this.$router.currentRoute._value.query.specialization_id:"",
        consaltations:[],
        consltaions_arr:[],
        page: 1,
        // welcomeData_:this.welcomeData,
        search_form: {
          specialization_id:"",
          status: "all",
          page:this.page,
          per_page:2
        },
        search_data:[],
        // page:1,

        showLoader: false,
        listItems: [],

        paginate:{perpage:2,page:this.page},
        totalPages: 0,
        totalRecords: 0,
        recordsPerPage: 10,
        enterpageno: ''


      }
    },
    computed: {
      ...mapGetters(["isLoggedIn"]),
      ...mapState(['user', 'token'])
    },
    methods:{

      getConsaltations:async function (status='all') {
        //check login first
        if(! this.isLoggedIn){
          console.log("check false")
          this.checkLogin = false
          //  this.$router.push({path: '/login'});
          this.$router.push({ name: 'login', query: { redirect: '/myconsaltations' } });
        } else {
          console.log("check true")
          this.checkLogin = true
        }

        const response = await axios.get(this.$apiUrl + 'consulation/list', {
          params:{
            page:this.page,
            per_page:2,
            status:status
          }/*{page:this.page}*/,//this.paginate,
          headers: {
            'Accept-Language': this.locale,
            'Authorization':'Bearer'+this.token
          }
        });
        if (response.data.status == true) {
          this.showLoader = false
          this.consaltations= response.data.data.data

          console.log(this.consaltations)
          this.totalPages = response.data.data.meta.last_page // Calculate total records
          this.totalRecords = response.data.data.meta.last_page*response.data.data.meta.per_page
        } else {
          swal2.fire("error", response.data.message, "error");

        }
      },
      checkLogin: async function () {
        if (this.userToken == "") {
          this.checkLogin = false
        } else {
          this.checkLogin = true
        }
      },
    onPageChange (page) {
      this.page = page
      console.log(this.page);
      this.getConsaltations()
    },
    onChangeRecordsPerPage () {
      this.getConsaltations()
    },
    gotoPage () {
      if (!isNaN(parseInt(this.enterpageno))) {
        this.page = parseInt(this.enterpageno)
        this.getConsaltations()
      }
    }
    },
    mounted:function(){

      this.getConsaltations()

    }
  }
</script>
<style>
  .img-fluid{max-width:50% !important;}
</style>
