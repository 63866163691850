<template>
  <!-- Inne Page Banner Area Start Here -->
  <section class="inner-page-banner bg-common inner-page-top-margin"  :style="{backgroundImage:'url(\'' +this.$attachUrl+'images/'+this.navData.doctors_banner+ '\')'}">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="breadcrumbs-area">
            <h1 v-if="locale=='en'">SignUp</h1><h1 v-else> انشاء حساب</h1>
            <ul>
              <li>
                <a v-if="locale=='en'" href="#">Home</a>
                <a v-else href="#">الرئيسيه</a>
              </li>
              <li v-if="locale=='en'">SignUp</li>
              <li v-else>انشاء حساب</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Inne Page Banner Area End Here -->
  <!-- Faq Form Area Start Here -->
  <section class="faq-wrap-layout2 bg-light-accent100" v-show="sign==true">
    <div class="container">
      <div class="row justify-content-center d-flex">
        <div class="col-lg-8">
          <div class="faq-ask-question-layout1">
            <h2 class="title"> <span v-if="locale=='en'" >SignUp</span> <span v-if="locale=='ar'" >مستخدم جديد</span> </h2>
            <form class="faq-question-box">
              <div class="row">
                <div class="col-md-6 form-group">
                  <input type="text" placeholder="First Name *" v-model="userdata.name" class="form-control" name="name" data-error="Phone field is required" required>
                  <div class="help-block with-errors"></div>
                </div>
                <div class="col-md-6 form-group">
                  <input type="email" placeholder="Last Name *"  v-model="userdata.last_name"  class="form-control" name="last_name" data-error="E-mail field is required" required>
                  <div class="help-block with-errors"></div>
                </div>
                <div class="col-md-6 form-group">
                  <input type="email" placeholder="E-mail *" class="form-control"  v-model="userdata.email"  name="email" data-error="Subject field is required" required>
                  <div class="help-block with-errors"></div>
                </div>
              <!--  <div class="col-md-6 form-group">
                  <input type="text" placeholder="birth_date *" class="form-control"  v-model="userdata.birth_date"  name="birth_date" data-error="Subject field is required" required>
                  <div class="help-block with-errors"></div>
                </div>-->
                <div class=" col-md-6 form-group date" data-provide="datepicker">
                  <input type="date" class="form-control"  v-model="userdata.birth_date" />


                </div>
                <div class="col-md-6 form-group">
                  <input type="text" placeholder="Phone *" class="form-control"  v-model="userdata.phone"  name="phone" data-error="Subject field is required" required>
                  <div class="help-block with-errors"></div>
                </div>
                <div class="col-md-6 form-group">
                  <input type="password" placeholder="password *" class="form-control"  v-model="userdata.password"  name="password" data-error="password field is required" required>
                  <div class="help-block with-errors"></div>
                </div>

                <div class="col-12 form-group">
                  <button class="item-btn" @click.prevent="saveForm()"> <span v-if="locale=='en'" >Register</span> <span v-if="locale=='ar'" >تسجيل</span></button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Faq Form Area End Here -->
  <section class="faq-wrap-layout2 bg-light-accent100" v-show="verfy==true">
    <div class="container">
      <div class="row justify-content-center d-flex">
        <div class="col-lg-8">
          <div class="faq-ask-question-layout1">
            <h2 class="title"> <span v-if="locale=='en'" >verfication code</span> <span v-if="locale=='ar'" >رمز التأكيد </span> </h2>
            <div id="form" class="faq-question-box">
              <div class="row justify-content-center">

                <input type="number" maxLength="1" name="number1" v-model="number1" size="1" min="0" max="9" pattern="[0-9]{1}" />
                <input type="number" maxLength="1" name="number2" v-model="number2" size="1" min="0" max="9" pattern="[0-9]{1}" />
                <input type="number" maxLength="1" name="number3" v-model="number3"  size="1" min="0" max="9" pattern="[0-9]{1}" />
                <input type="number" maxLength="1" name="number4" v-model="number4"  size="1" min="0" max="9" pattern="[0-9]{1}" />
                <input type="number" maxLength="1" name="number5" v-model="number5"  size="1" min="0" max="9" pattern="[0-9]{1}" />
                <input type="number" maxLength="1" name="number6" v-model="number6"  size="1" min="0" max="9" pattern="[0-9]{1}" />
                <div class="col-12 form-group">
                     <button class=" item-btn" @click.prevent="vefySign()" v-if="locale=='en'">Verify</button>
                     <button class=" item-btn" @click.prevent="vefySign()" v-if="locale=='ar'">ادخل رمز التأكيد</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

  import axios from 'axios'
  import swal2 from 'sweetalert2'
  export default {
    name: 'SignupView',
    props:{navData:Array,specializeData:Array,doctorId:String, msg: String},
    components: {

    },
    data() {
      return {
        userdata: {
          password: this.password,
          name: this.name,
          last_name: this.last_name,
          phone: this.phone,
          birth_date:this.birth_date,
          role_id: 1,
          city_id: 1,
          active: 0

        },
        verfy:false,
        sign:true,
        phone:"",
        code:"",
        number1:this.number1,
        number2:this.number2,
        number3:this.number3,
        number4:this.number4,
        number5:this.number5,
        number6:this.number6,

        verfydata:{
          phone:this.phone,
          verification_code:this.number1+this.number2+this.number3+this.number4+this.number5+this.number6

        }

      }
    },
    computed() {
          return{
            verification_code:this.number1+this.number2+this.number3+this.number4+this.number5+this.number6
          }
    },
    methods:{

      saveForm: async function () {

        const response=  await axios.post(this.$apiUrl + "auth/register",this.userdata,{
          headers: {
            'Accept-Language': this.locale,
           // 'Authorization':'Bearer'+this.token
          }
        }).then(response => {

          // success callback

          if (response.data.status==true) {
            this.verfy=true
            this.sign=false
            this.phone=this.userdata.phone
            alert(this.phone)
            swal2.fire("register done varifaction code send", "", "success").then((result) => {

              if (result.isConfirmed) {

                close()
              } else if (result.isDenied) {
                Swal.fire('error when save', '', 'info')
              }
            });
          } else {
            if((typeof((response.data.errors.descreption)) != "undefined" )&& ((response.data.errors.descreption) !== null))
              {
                swal2.fire(response.data.errors.descreption[0], '', "error")

              }
              else
              {
                swal2.fire(response.data.errors, '', "error")
              }

          }
        }, function (response) {

          // error callback
          swal2.fire('error', '', "error");

        });

       /* if (response.status==true) {
          swal2.fire("done", "", "register done varifaction code send").then((result) => {

            if (result.isConfirmed) {
              close()
            } else if (result.isDenied) {
              Swal.fire('error when save', '', 'info')
            }
          });



        } else {
          swal2.fire(response.errors.descreption[0], '', "error");

        }*//*.then((res) => {
          swal2.fire("done", "", "register done varifaction code send").then((result) => {

            if (result.isConfirmed) {
              close()
            } else if (result.isDenied) {
              Swal.fire('error when save', '', 'info')
            }
          });

        })*/

      },

      vefySign:  async function () {
        alert(this.phone)

        console.log(this.number1)

      let verfydata={
          phone:this.phone,
          verification_code:this.number6+''+this.number5+''+this.number4+''+this.number3+''+this.number2+''+this.number1

        }
        console.log(verfydata)
        const response= await axios.post(this.$apiUrl + "auth/verify",verfydata,{
          headers: {
            'Accept-Language': this.locale,
            // 'Authorization':'Bearer'+this.token
          }
        }).then(response => {
         console.log(response)
          // success callback

          if (response.data.status == true) {
            swal2.fire("done", "", "success").then((result) => {

              if (result.isConfirmed) {
                close()
              }
              this.$router.push({ name: 'login', query: { redirect: '/' } })
            });

          } else {
               // swal2.fire('error invalid code', '', "error")
            this.$router.push({ name: 'login', query: { redirect: '/' } })
          }
        })

      }
    }
  }
</script>
