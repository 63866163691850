<template>

  <!-- Inne Page Banner Area Start Here -->
  <section class="inner-page-banner bg-common inner-page-top-margin"  :style="{backgroundImage:'url(\'' +this.$attachUrl+'images/'+this.navData.doctors_banner+ '\')'}">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="breadcrumbs-area">
            <h1 v-if="locale=='en'">Doctor Details</h1><h1 v-else>تفاصيل الطبيب</h1>
            <ul>
              <li>
                <a v-if="locale=='en'" href="#">Home</a>
                <a v-else href="#">الرئيسيه</a>
              </li>
              <li v-if="locale=='en'">Doctors</li>
              <li v-else>تفاصيل الطبيب</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Inne Page Banner Area End Here -->

  <!-- Doctors Detail Start Here -->
  <section class="blog-detail-wrap">
    <div class="container">
      <div class="row">
        <div class="col-xl-9 col-lg-8 col-12">
          <div class="blog-detail-box">
              <div class="blog-author">
                <div class="media media-none--xs">
                  <img v-bind:src="doctor_detail_arr.avatar_url" alt="Blog Author" class="rounded-circle media-img-auto" style="width:130px;">

                  <div class="media-body">
                    <div class="col-xl-8 col-lg-8 col-8">
                      <h4 class="author-title">{{doctor_detail_arr.name}}</h4>
                      <p>{{doctor_detail_arr.work_details}}</p>
                    </div>

                    <ul class="author-social">
                      <li>
                        <span> <span v-if="locale=='en'" >price:</span> <span v-if="locale=='ar'" >السعر:</span>{{consaltion_arr.price}}</span>
                      </li>
                      <li>
                        <span><span v-if="locale=='en'" >date:</span> <span v-if="locale=='ar'" >التاريخ:</span>{{consaltion_arr.date}}</span>
                      </li>
                      <li>
                        <span v-if="locale=='en'" >status:</span> <span v-if="locale=='ar'" >الحاله:</span> <button class="btn btn-danger">{{consaltion_arr.status}}</button>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>

              <div class="blog-comment">
                <h3>{{countchat}} <span v-if="locale=='en'" >Messages</span> <span v-if="locale=='ar'" >رسائل</span> </h3>
                <ul>
                  <li v-for="(chat,index) in (chatList)" >
                    <div class="media media-none--xs col-12">
                      <img v-bind:src="chat.avatar_url" alt="chat" class="rounded-circle media-img-auto">
                      <div class="media-body">
                        <h4>{{chat.from_name}} <span>, {{chat.created_at}}</span></h4>
                        <p>{{chat.message}}</p>
                        <!--<a href="#" class="item-btn">Reply</a>-->
                      </div>

                    </div>
                    <div  class="chat_img col-12">
                      <div v-for="(attach,index) in (chat.attachments)" class="col-2">
                        <img v-bind:src="attach.path_url">
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="blog-form-box">
                <h3 class="title title-bar-primary4"> <span v-if="locale=='en'" >Send Message</span> <span v-if="locale=='ar'" >ارسل رساله</span></h3>
                <form action="" enctype="multipart/form-data" method="post" class="contact-form-box">

                  <div class="row gutters-10">

                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="col-form-label"> <span v-if="locale=='en'" > Enter Message</span> <span v-if="locale=='ar'" >اكتب رساله</span> <span class="text-danger">*</span></label>
                        <textarea   v-model="message" class="form-control" type="text" name="about_consalt" rows="10"></textarea>
                      </div>
                    </div>
                    <!--<div class="col-md-12">
                      <h3>Upload your images</h3>
                      <input type="file" id="files" name="files[]" multiple />
                    </div>-->
                    <div  class="col-md-12">
                      <h3><span v-if="locale=='en'" >Upload your files</span> <span v-if="locale=='ar'" >رفع صوره او ملف</span></h3>

                      <input type="file" @change="uploadImage" name="files[]" multiple id="image"  accept="image/*" >
                      <div class="form-group">
                        <div id="image_preview" style="width:100%;">
                          <div v-for="(image_url,index) in imagePath" class='img-div' :id='"img-div"+index'><img v-bind:src='this.$attachUrl+image_url' class='img-responsive image img-thumbnail'><div class='middle'><button id='action-icon' @click="deleteFile(index)" :value='"img-div"+index' class='btn btn-danger'><i class='fa fa-trash'></i></button></div></div><div class='clear-fix'></div>
                        </div>
                      </div>
                      <!-- <button v-on:click="open" id="upload_widget" class="cloudinary-button">
                      Upload files
                    </button>-->

                    </div>

                    <div class="col-12 form-group margin-b-none">
                      <button class="item-btn" @click.prevent="saveForm()" type="add"><span v-if="locale=='en'" > Send Message</span> <span v-if="locale=='ar'" >ارسال</span></button>
                    </div>
                  </div>
                </form>

              </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-12">
          <div class="consalt_drug">
            <h3> <span v-if="locale=='en'" >Drugs</span> <span v-if="locale=='ar'" >الأدويه</span></h3>
            <h3> <span v-if="locale=='en'" ><i class="fi fi-ts-file-edit"></i> Drugs by doctor</span> <span v-if="locale=='ar'" > <i class="fi fi-ts-file-edit"></i>أدويه مرسله من الطبيب</span></h3>
            <ul>

              <li v-for="(drug,index) in (drugs)">

                <div class="media media-none--xs">
                  <span class="number_class">{{index+1}}</span>
                  <div class="media-body">
                    <h4>{{drug.name}} <span>{{drug.created_at}}</span></h4>
                    <p>{{drug.drug_note}}</p>

                  </div>
                </div>
              </li>

            </ul>
          </div>
        </div>
      </div>
    </div>

  </section>
  <!-- Doctors Detail End Here -->
</template>
<style>
  .number_class{
    background:#dc3545;
    width: 20px;
    align-content: center;
    text-align: center;
    border-radius: 50%;
  }
</style>
<script>
  import axios from 'axios'
  import swal2 from 'sweetalert2'
  import { mapState } from 'vuex'
  import { mapGetters } from "vuex";

  export default {
    name:'Consaltation details',

    props:{navData:Array,specializeData:Array,doctorId:String,consaltId:String, msg: String},
    data(){
      return{
        consaltaionDetail:[],
        doctor_detail_arr:[],
        consaltion_arr:[],
        drugs:[],
        chatList:[],
        msgFrom:null,
        msgTo:null,
        countchat:0,
        checkLogin:false,
        imagePath:[],
        consaltId:this.consaltId,

        message:"",

      }
    },
    computed: {
      ...mapGetters(["isLoggedIn"]),
      ...mapState(['user', 'token'])
    },

    methods: {
      getConsaltDetail: async function () {

        //check login first
        if(!this.isLoggedIn){
          this.checkLogin = false
          //  this.$router.push({path: '/login'});
          this.$router.push({ name: 'login', query: { redirect: '/'} });
        } else {
          this.checkLogin = true
        }
       let response = await axios.get(this.$apiUrl + "consulation/get-consultation/" + this.consaltId, {
          headers: {
            'Accept-Language': this.locale,
            'Authorization':'Bearer'+this.token
          }
        })
        if (response.data.status == true) {

          this.consaltaionDetail = response.data.data
          this.doctor_detail_arr=this.consaltaionDetail.doctor_details
          this.consaltion_arr=response.data.data.consulations
          this.msgFrom= this.consaltion_arr.patient_id
          this.$emit('msgFrom', this.msgFrom);
          console.log(this.msgFrom)
          this.msgTo= this.consaltion_arr.doctor_id
          console.log(this.msgTo)

        } else {
          swal2.fire("error", response.data.message, "error");

        }
      },
      getDrugs: async function () {
        //check login first
        if(!this.isLoggedIn){
          this.checkLogin = false
          //  this.$router.push({path: '/login'});
          this.$router.push({ name: 'login', query: { redirect: '/' } });
        } else {
          this.checkLogin = true
        }
        const response = await axios.get(this.$apiUrl + "consulation/get-consulat-drags?consulation_id=" + this.consaltId, {
          headers: {
            'Accept-Language': this.locale,
            'Authorization':'Bearer'+this.token
          }
        })
        if (response.data.status == true) {


          this.drugs= response.data.data


        } else {
          swal2.fire("error", response.data.message, "error");

        }
      },
      getChatList: async function () {


        const response = await axios.get(this.$apiUrl + "consulation/consulat-list-chat?consalt_id="+this.consaltId+"&from_id="+ this.msgFrom +"&to_id="+ this.msgTo, {
          headers: {
            'Accept-Language': this.locale,
            'Authorization':'Bearer'+this.token
          }
        })
        if (response.data.status == true) {


          this.chatList= response.data.data
          this.countchat=this.chatList.length
         console.log(this.chatList)

        } else {
          swal2.fire("error", response.data.message, "error");

        }
      },
      checkLogin:  async function () {
        if (this.userToken == "") {
          this.checkLogin = false
        } else {
          this.checkLogin = true
        }
      },
      saveForm: async function () {

          console.log(this.imagePath);
          this.messageData={message:this.message,to:this.msgTo, consalt_id:this.consaltId,imgPath:this.imagePath}
          console.log(this.messageData);
          console.log(this.imagePath);
          axios.post(this.$apiUrl + "consulation/send-message" , this.messageData, {
            headers: {
              'Accept-Language': this.locale,
              'Authorization':'Bearer'+this.token
            }
          }).then((res) => {
            this.getChatList()
            swal2.fire("done", "", "success").then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                close()
              } else if (result.isDenied) {
                Swal.fire('error when save', '', 'info')
              }
            });

            // answers={type:,answer_id:,question_id:}
            /*axios.post(this.$apiUrl + "specialize/add-patient-answer" , this.messageData, {
              headers: {
                'Accept-Language': this.locale,
                'Authorization':'Bearer'+this.token
              }*/
          })

      },
      uploadImage (e) {
        let img = e.target.files
        console.log(img)
        let fd= new FormData()

        fd.append('files',img)

        console.log(fd)
        axios.post(this.$apiUrl + 'consulation/uploadFiles', img, {
          headers: {
            'Accept-Language': this.locale,
            'Authorization':'Bearer'+this.token
          }
        })
        /* axios.post(this.$apiUrl + 'consulation/uploadFiles', fd, {
           headers: {
             'Accept-Language': this.locale,

           }
         } )*/
          .then(res => {
            this.imagePath =res.data.data

            console.log(this.imagePath)

          })
      },
      deleteImage:function (key){
        console.log("delete")
        console.log(key)
      },
    },
    async created (){
      await this.getConsaltDetail()
      this.getDrugs()
      this.getChatList()
    }/*,
    mounted:function(){

      this.getConsaltDetail()

      this.getDrugs()
      this.getChatList()
    }*/


  }

</script>
<style>
  #add_appoint.modal-content{
    background: #d5e8f2;
  }
  #add_appoint.modal-header{
    background: #396cf0;
  }
  @media(min-width: 768px) {
    .img-div {
      position: relative;
      width: 32%;
      float:left;
      margin: 5px 10px;
    }
  }

  @media(max-width: 467px) {
    .img-div {
      position: relative;
      width: 100%;
      margin: 5px 10px;
    }
  }

  @media(min-width: 467px) {
    .img-div {
      position: relative;
      width: 45%;
      float:left;
      margin: 5px 10px;
    }
  }

  .image {
    opacity: 1;
    display: block;
    min-width:  100%;
    aspect-ratio:  3/2;
    transition: .5s ease;
    backface-visibility: hidden;
  }

  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  .img-div:hover .image {
    opacity: 0.3;
  }

  .img-div:hover .middle {
    opacity: 1;
  }
  .img-div{
    width: 27%;
  }
</style>

