<template>
  <!-- Inne Page Banner Area Start Here -->
  <section class="inner-page-banner bg-common inner-page-top-margin"  :style="{backgroundImage:'url(\'' +this.$attachUrl+'images/'+this.navData.doctors_banner+ '\')'}">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="breadcrumbs-area">
            <h1 v-if="locale=='en'">Login</h1><h1 v-else> دخول</h1>
            <ul>
              <li>
                <a v-if="locale=='en'" href="#">Home</a>
                <a v-else href="#">الرئيسيه</a>
              </li>
              <li v-if="locale=='en'">Login</li>
              <li v-else>تسجيل الدخول </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Inne Page Banner Area End Here -->
  <!-- Contact Us Area Start Here -->
  <section class="contact-wrap-layout">
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-lg-6">
          <div class="contact-box-layout1">
            <h3 class="title title-bar-primary6 d-flex justify-content-center" ><span v-if="locale=='en'">Login</span><span v-else>تسجيل دخول</span></h3>
            <form class="contact-form-box" id="contact-form">
              <div class="row">
                <div>
                  <p class="text-danger">{{message_error}}</p>
                  <ul>
                    <li class="text-danger" v-for="(error) in errors"> {{error}}</li>
                  </ul>
                </div>
                <div class="col-md-12 form-group">
                  <input type="text" placeholder="Phone *" class="form-control" name="phone"
                         :data-error="errors.phone"   v-model="form.phone" required>
                  <div class="help-block with-errors"></div>
                </div>
                <div class="col-md-12 form-group">
                  <input type="password" placeholder="Password *" class="form-control" name="password"
                         :data-error="errors.password"   v-model="form.password" required>
                  <div class="help-block with-errors"></div>
                </div>
                <div class="col-12 form-group d-flex justify-content-center">
                  <button type="submit" class="item-btn " @click.stop.prevent="login()" ><span v-if="locale=='en'">Submit Message</span><span v-else> دخول</span></button>
                </div>
              </div>
              <div class="form-response"></div>
            </form>
          </div>
        </div>

      </div>
    </div>
  </section>
  <!-- Contact Us Area End Here -->
</template>
<script>
  import axios from 'axios'
  import swal2 from 'sweetalert2'
  import { mapMutations } from "vuex";
  import { mapGetters } from "vuex";
  export default {
    components: {

    },
    name:'Login',
    computed: {
      ...mapGetters(["isLoggedIn"])
    },
    props:{navData:Array,specializeData:Array},
    data(){
      return{
        form: {
          phone: "",
          password:"",
        },
        errors: [],
        message_error:""

      }
    },
    methods: {
      ...mapMutations(["setUser", "setToken"]),
      login: async function () {
      //  const response =
          axios.post(this.$apiUrl + "auth/login",  this.form,{

            headers: {
              'Accept-Language': this.locale,
            }
          })
          .then((res) => {
            console.log(res)
            this.message_error=""
            this.errors=[]
            this.userToken=res.data.data.token
            console.log(this.userToken)
            this.setUser(res.data.data.user);
            this.setToken(res.data.data.token);
            if(this.$route.query.redirect) {
              this.$router.push(this.$route.query.redirect)
            }else{
              this.$router.push('/')
            }
            //this.$router.router.back()
            //Perform Success Action
          })
          .catch((error) => {
            console.log(error)
            if(error!== "undefined") {
              if (error.response.data.code == 401) {
                if (this.locale == "ar")
                  this.message_error = "رقم الهاتف او كلمة السر غير صحيح"
                else
                  this.message_error = "phone or password is not valid"
              } else {
                console.log(error.response.data.errors["descreption"]);

                this.errors = error.response.data.errors["descreption"]
                // error.response.status Check status code
              }
            }
          }).finally(() => {
            //Perform action in always
          });
        /* console.log(response)
         console.log(response.status)
        if (response.code == 200) {

          this.$router.router.back()
        } else if(response.code == 422 ) {
          swal2.fire("error", response.data.message, "error");

        }else{
         console.log("error")
        }*/
      },
    },
    mounted:function(){

    }
  }

</script>
<style>

</style>

