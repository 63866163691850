import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "./assets/all_style.css"
import jQuery from 'jquery'
import 'owl.carousel'
import VueRouter from 'vue-router'
import store from './store'




//import {createRouter,createWebHistory} from "vue-router";
//import HomeComponent from '@/components/HomeComponent'
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

import 'bootstrap';
let owl_carousel = require('owl.carousel');
window.fn =owl_carousel;
import  'magnific-popup';

const app=createApp(App)
app.config.globalProperties.$apiUrl = "https://eihospital.com/eihospital/api/"
app.config.globalProperties.$attachUrl = "https://eihospital.com/eihospital/public/"
app.config.globalProperties.locale = "ar"
app.config.globalProperties.userToken = ""
app.use(store)
app.use(router)


app.mount('#app')
