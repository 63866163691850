<template>
  <home-slider/>
  <!-- About Area Start Here -->
  <section class="about-wrap-layout1" data-bg-image="img/figure/figure7.jpg">
    <div class="container">
      <div class="row">
        <div class="about-box-layout1 order-xl-2 col-xl-5 col-12">
          <div class="item-content">
            <h2 v-if="locale=='en'" class="item-title">{{welcomeData.welcome_title_en}}</h2>
            <h2 v-else class="item-title">{{welcomeData.welcome_title_ar}}</h2>
          <!--  <div class="sub-title">
            </div>-->
            <p v-if="locale=='en'">{{welcomeData.welcome_description_en}}</p>
            <p v-else>{{welcomeData.welcome_description_ar}}</p>
           <!-- <img  alt="sign" class="img-fluid">-->
          </div>
        </div>
        <div class="about-box-layout2 order-xl-3 col-xl-4 col-lg-7 col-12">
          <ul>
            <li><router-link to="/doctor"><i class="far fa-calendar-alt"></i> <span v-if="locale=='en'">Request Appoinment</span> <span v-else>حجز ميعاد</span> </router-link></li>
            <li><router-link to="/doctor"><i class="far fa-user"></i><span v-if="locale=='en'">Find Doctors</span> <span v-else>حجز مع طبيب </span></router-link></li>
           <!-- <li><router-link to="/appoint"><i class="fas fa-map-marker-alt"></i>Find Locations</router-link></li>-->
            <li><router-link to="/contact"><i class="fas fa-phone"></i>Emergency Contact</router-link></li>
          </ul>
        </div>
        <div class="about-box-layout2 order-xl-1 col-xl-3 col-lg-5 col-12">
          <div class="item-img">
            <img v-bind:src="$attachUrl+'images/'+welcomeData.welcome_img"  style="max-width=100% !important;" alt="about" class="">
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- About Area End Here -->
  <p></p>
  <!-- Departments Area Start Here -->
  <section class="departments-wrap-layout2 bg-light-secondary100">
    <img class="left-img img-fluid" v-bind:src="$attachUrl+'images/'+welcomeData.specialize_background"  alt="figure">
    <div class="container">
      <div class="section-heading heading-dark text-left heading-layout1">
        <h2><span v-if="locale=='en'">Our Departments</span><span v-else>التخصصات</span></h2>
        <p><span v-if="locale=='en'">Dedicated Services</span><span v-else>الخدمات</span></p>
        <div id="owl-nav1" class="owl-nav-layout1">
          <span @click="prev" class="rt-prev"> <i class="fas fa-chevron-right"></i></span>
          <input  class="hidden" type="number" min="0" max="9" v-model="currentSlide"/>
          <span @click="next" class="rt-next"><i class="fas fa-chevron-left"></i></span>
        </div>
      </div>

      <carousel   class="rc-carousel nav-control-layout2" ref="carousel" v-model="currentSlide" snapAlign="start" :itemsToShow="4" :wrapAround="true" :transition="500" :data-loop="true" :data-items="4" :data-margin="20"
           :data-autoplay="false" :data-autoplay-timeout="5000"  :data-smart-speed="2000"
           :data-dots="false" :data-nav="false" :data-nav-speed="false" :data-r-x-small="1" :data-r-x-small-nav="true"
           :data-r-x-small-dots="false" :data-r-x-medium="2" :data-r-x-medium-nav="false" :data-r-x-medium-dots="false"
           :data-r-small="2" :data-r-small-nav="false" :data-r-small-dots="false" :data-r-medium="3"
           :data-r-medium-nav="false" :data-r-medium-dots="false" :data-r-large="4" :data-r-large-nav="false"
           :data-r-large-dots="false" :data-r-extra-large="4" :data-r-extra-large-nav="false"
           :data-r-extra-large-dots="false">
          <!-- :items-to-show="4" :autoplay="0" :wrap-around="true" :dir="rtl"  >-->


          <slide v-for="(specialize,key) in specializeData" :key="key">
              <div  class="departments-box-layout2">
              <span class="departments-sl">{{key}}</span><!--specializations/-->
              <div class="item-icon"><img v-bind:src="$attachUrl+'images/'+specialize.img"  alt="figure"  @error='$event.target.src=$attachUrl+"images/specializations/default.jpeg"'></div>
              <h3 class="item-title"><router-link to="specializations/{{specialize.id}}"><span v-if="locale=='en'">{{specialize.name_en}}</span><span v-else>{{specialize.name_ar}}</span></router-link></h3>
              <!-- <p></p>
              <a class="item-btn" href="#">READ MORE<i class="fas fa-long-arrow-alt-left"></i></a>-->
              </div>
          </slide>

      <!--  <template #addons>
          <Navigation />
        </template>-->
      </carousel>
    </div>
  </section>
  <!-- Departments Area End Here -->
  <!-- Featured Area Start Here -->
  <section class="features-wrap-layout1">
    <div class="features-box-layout1 d-lg-flex bg-primary100">
      <div class="item-inner-wrapper">
        <div class="item-content d-flex align-items-center">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="item-content-inner content-light">
                  <h2 class="item-title"><span v-if="locale=='en'">Choose the best for your health</span><span v-else>اختر الافضل لصحتك </span></h2>
                <!--  <p><span v-if="locale=='en'">Dwisi enim ad minim veniam, quis laore nostrud exerci tation ulm hedi corper</span><span v-else>مع استشاره صحتك في أمان</span>
                    turet suscipit lobortis.</p>
                  <ul class="list-item">
                    <li><span v-if="locale=='en'">Free Consultation</span> <span v-else>استشارات مجانيه</span></li>
                    <li><span v-if="locale=='en'">Quality Doctors</span><span v-else>أفضل الاطباء</span></li>
                    <li><span v-if="locale=='en'">Affordable Price</span><span v-else>أسعار رمزيه</span></li>
                    <li><span v-if="locale=='en'">24/7 Opened</span><span v-else> متاح 24 ساعه</span></li>
                  </ul>-->
                  <div v-if="locale=='en'" v-html="features.content_en"></div>
                  <div class="list-item" v-else="locale=='ar'" v-html="features.content_ar"> </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="item-inner-wrapper">
        <img v-bind:src="$attachUrl+'images/'+features.img_"  class="img-responsive" alt="figure">
      </div>
    </div>

  </section>
  <!-- Featured Area End Here -->

  <!-- Team Area Start Here -->
  <section class="team-wrap-layout1 bg-light-secondary100">
    <img class="left-img img-fluid" v-bind:src="$attachUrl+'images/web/down.jpg'" alt="figure">
    <img class="right-img img-fluid" v-bind:src="$attachUrl+'images/web/down.jpg'" alt="figure">
    <div class="container">
      <div class="section-heading heading-dark text-left heading-layout1">
        <h2 v-if="locale=='en'" >Specialist Doctors</h2>
        <h2 v-else >الأطباء الأعلي تقييما</h2>
        <!--<p>Experienced Doctor</p>-->

        <div id="owl-nav2" class="owl-nav-layout1">
          <span @click="prev" class="rt-prev"> <i class="fas fa-chevron-right"></i></span>
          <input  class="hidden" type="number" min="0" max="9" v-model="currentSlideDoctor"/>
          <span @click="next" class="rt-next"><i class="fas fa-chevron-left"></i></span>
        </div>
      </div>
      <carousel class="rc-carousel nav-control-layout2" ref="carousel" v-model="currentSlideDoctor" snapAlign="start" :itemsToShow="4" :wrapAround="true" :transition="500" :data-loop="true" >
        <slide v-for="(doctor_h_rate,index) in highRateDoctors" :key="index">
          <div class="team-box-layout2">
            <div class="item-img">
              <img v-bind:src="doctor_h_rate.avatar_url"   @error='$event.target.src=$attachUrl+"images/users/profile.jpg"' class="img-fluid rounded-circle">
              <ul class="item-icon">
                <li>
                  <router-link to="/doctor-detail/{{doctor_h_rate.id}}">
                    <i class="fas fa-plus"></i>
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="item-content">
              <h3 class="item-title h_rate_doctors_title">
                <router-link to="/doctor-detail/{{doctor_h_rate.id}}">{{doctor_h_rate.name}}{{doctor_h_rate.last_name}} </router-link>
              </h3>
              <p v-if="locale=='en'" >{{doctor_h_rate.specialize.name_en}}</p>
              <p v-if="locale=='ar'" >{{doctor_h_rate.specialize.name_ar}}</p>
            </div>
            <div class="item-schedule">
              <ul>
                <li class="h_rate_doctors_desc">{{doctor_h_rate.work_details}}
                  <span></span>
                </li>
              </ul>
              <router-link to="/doctor-detail" class="item-btn"><span v-if="locale=='en'" >MAKE AN APPOINTMENT</span> <span v-if="locale=='ar'"> احجز ميعاد </span></router-link>
            </div>
          </div>
        </slide>

      </carousel>
    </div>

  </section>
  <!-- Team Area End Here -->
  <!-- Schedule Area Start Here -->
  <!--<section class="class-schedule1">
    <div class="container">
      <div class="section-heading heading-dark text-center heading-layout1">
        <h2>Specialist Doctors</h2>
        <p>Experienced Doctor</p>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="class-schedule-wrap1">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th>
                    <div class="schedule-time-heading">Time</div>
                  </th>
                  <td>
                    <div class="schedule-day-heading">Monday</div>
                  </td>
                  <td>
                    <div class="schedule-day-heading">Tuesday</div>
                  </td>
                  <td>
                    <div class="schedule-day-heading">Wednesday</div>
                  </td>
                  <td>
                    <div class="schedule-day-heading">Thursday</div>
                  </td>
                  <td>
                    <div class="schedule-day-heading">Friday</div>
                  </td>
                  <td>
                    <div class="schedule-day-heading">Saturday</div>
                  </td>
                  <td>
                    <div class="schedule-day-heading">Sunday</div>
                  </td>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th scope="row">
                    <div class="schedule-time-wrapper">08.00am</div>
                  </th>
                  <td>
                    <div class="schedule-item-wrapper">
                      <div class="media">
                        <div class="item-img">
                          <img src="../assets/img/team/team7.png" alt="team" class="img-fluid rounded-circle">
                        </div>
                        <div class="media-body">
                          <h3 class="title">Steven Smith</h3>
                          <div class="item-ctg">Contact Lens</div>
                          <router-link to="/doctor-detail" class="item-btn btn-fill size-xs radius-4">View
                            Profile</router-link>
                        </div>
                      </div>
                      <div class="item-ctg">Contact Lens</div>
                      <div class="item-time">08.00am - 09.00am</div>
                      <div class="item-team">Dr.Mike Hussy</div>
                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">
                      <div class="media">
                        <div class="item-img">
                          <img src="../assets/img/team/team7.png" alt="team" class="img-fluid rounded-circle">
                        </div>
                        <div class="media-body">
                          <h3 class="title">Steven Smith</h3>
                          <div class="item-ctg">Contact Lens</div>
                          <router-link to="/doctor-detail" class="item-btn btn-fill size-xs radius-4">View
                            Profile</router-link>
                        </div>
                      </div>
                      <div class="item-ctg">Contact Lens</div>
                      <div class="item-time">08.00am - 09.00am</div>
                      <div class="item-team">Dr.Mike Hussy</div>
                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">

                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">
                      <div class="media">
                        <div class="item-img">
                          <img src="../assets/img/team/team7.png" alt="team" class="img-fluid rounded-circle">
                        </div>
                        <div class="media-body">
                          <h3 class="title">Steven Smith</h3>
                          <div class="item-ctg">Contact Lens</div>
                          <router-link to="/doctor-detail" class="item-btn btn-fill size-xs radius-4">View
                            Profile</router-link>
                        </div>
                      </div>
                      <div class="item-ctg">Contact Lens</div>
                      <div class="item-time">08.00am - 09.00am</div>
                      <div class="item-team">Dr.Mike Hussy</div>
                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">
                      <div class="media">
                        <div class="item-img">
                          <img src="../assets/img/team/team7.png" alt="team" class="img-fluid rounded-circle">
                        </div>
                        <div class="media-body">
                          <h3 class="title">Steven Smith</h3>
                          <div class="item-ctg">Contact Lens</div>
                          <router-link to="/doctor-detail"  class="item-btn btn-fill size-xs radius-4">View
                            Profile</router-link>
                        </div>
                      </div>
                      <div class="item-ctg">Contact Lens</div>
                      <div class="item-time">08.00am - 09.00am</div>
                      <div class="item-team">Dr.Mike Hussy</div>
                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">

                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">

                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <div class="schedule-time-wrapper">10.00am</div>
                  </th>
                  <td>
                    <div class="schedule-item-wrapper">
                      <div class="media">
                        <div class="item-img">
                          <img src="../assets/img/team/team7.png" alt="team" class="img-fluid rounded-circle">
                        </div>
                        <div class="media-body">
                          <h3 class="title">Steven Smith</h3>
                          <div class="item-ctg">Contact Lens</div>
                          <router-link to="/doctor-detail" class="item-btn btn-fill size-xs radius-4">View
                            Profile</router-link>
                        </div>
                      </div>
                      <div class="item-ctg">Contact Lens</div>
                      <div class="item-time">08.00am - 09.00am</div>
                      <div class="item-team">Dr.Mike Hussy</div>
                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">

                    </div>
                  </td>
                  <td>

                  </td>
                  <td>
                    <div class="schedule-item-wrapper">
                      <div class="media">
                        <div class="item-img">
                          <img src="../assets/img/team/team7.png" alt="team" class="img-fluid rounded-circle">
                        </div>
                        <div class="media-body">
                          <h3 class="title">Steven Smith</h3>
                          <div class="item-ctg">Contact Lens</div>
                          <router-link to="/doctor-detail" class="item-btn btn-fill size-xs radius-4">View
                            Profile</router-link>
                        </div>
                      </div>
                      <div class="item-ctg">Contact Lens</div>
                      <div class="item-time">08.00am - 09.00am</div>
                      <div class="item-team">Dr.Mike Hussy</div>
                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">

                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">

                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">
                      <div class="media">
                        <div class="item-img">
                          <img src="../assets/img/team/team7.png" alt="team" class="img-fluid rounded-circle">
                        </div>
                        <div class="media-body">
                          <h3 class="title">Steven Smith</h3>
                          <div class="item-ctg">Contact Lens</div>
                          <router-link to="/doctor-detail" class="item-btn btn-fill size-xs radius-4">View
                            Profile</router-link>
                        </div>
                      </div>
                      <div class="item-ctg">Contact Lens</div>
                      <div class="item-time">08.00am - 09.00am</div>
                      <div class="item-team">Dr.Mike Hussy</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <div class="schedule-time-wrapper">12.00pm</div>
                  </th>
                  <td>
                    <div class="schedule-item-wrapper">

                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">
                      <div class="media">
                        <div class="item-img">
                          <img src="../assets/img/team/team7.png" alt="team" class="img-fluid rounded-circle">
                        </div>
                        <div class="media-body">
                          <h3 class="title">Steven Smith</h3>
                          <div class="item-ctg">Contact Lens</div>
                          <router-link to="/doctor-detail" class="item-btn btn-fill size-xs radius-4">View
                            Profile</router-link>
                        </div>
                      </div>
                      <div class="item-ctg">Contact Lens</div>
                      <div class="item-time">08.00am - 09.00am</div>
                      <div class="item-team">Dr.Mike Hussy</div>
                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">

                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">
                      <div class="media">
                        <div class="item-img">
                          <img src="../assets/img/team/team7.png" alt="team" class="img-fluid rounded-circle">
                        </div>
                        <div class="media-body">
                          <h3 class="title">Steven Smith</h3>
                          <div class="item-ctg">Contact Lens</div>
                          <router-link to="/doctor-detail" class="item-btn btn-fill size-xs radius-4">View
                            Profile</router-link>
                        </div>
                      </div>
                      <div class="item-ctg">Contact Lens</div>
                      <div class="item-time">08.00am - 09.00am</div>
                      <div class="item-team">Dr.Mike Hussy</div>
                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">
                      <div class="media">
                        <div class="item-img">
                          <img src="../assets/img/team/team7.png" alt="team" class="img-fluid rounded-circle">
                        </div>
                        <div class="media-body">
                          <h3 class="title">Steven Smith</h3>
                          <div class="item-ctg">Contact Lens</div>
                          <router-link to="/doctor-detail" class="item-btn btn-fill size-xs radius-4">View
                            Profile</router-link>
                        </div>
                      </div>
                      <div class="item-ctg">Contact Lens</div>
                      <div class="item-time">08.00am - 09.00am</div>
                      <div class="item-team">Dr.Mike Hussy</div>
                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">
                      <div class="media">
                        <div class="item-img">
                          <img src="../assets/img/team/team7.png" alt="team" class="img-fluid rounded-circle">
                        </div>
                        <div class="media-body">
                          <h3 class="title">Steven Smith</h3>
                          <div class="item-ctg">Contact Lens</div>
                          <router-link to="/doctor-detail" class="item-btn btn-fill size-xs radius-4">View
                            Profile</router-link>
                        </div>
                      </div>
                      <div class="item-ctg">Contact Lens</div>
                      <div class="item-time">08.00am - 09.00am</div>
                      <div class="item-team">Dr.Mike Hussy</div>
                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">

                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <div class="schedule-time-wrapper">02.00pm</div>
                  </th>
                  <td>
                    <div class="schedule-item-wrapper">

                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">

                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">
                      <div class="media">
                        <div class="item-img">
                          <img src="../assets/img/team/team7.png" alt="team" class="img-fluid rounded-circle">
                        </div>
                        <div class="media-body">
                          <h3 class="title">Steven Smith</h3>
                          <div class="item-ctg">Contact Lens</div>
                          <router-link to="/doctor-detail" class="item-btn btn-fill size-xs radius-4">View
                            Profile</router-link>
                        </div>
                      </div>
                      <div class="item-ctg">Contact Lens</div>
                      <div class="item-time">08.00am - 09.00am</div>
                      <div class="item-team">Dr.Mike Hussy</div>
                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">
                      <div class="media">
                        <div class="item-img">
                          <img src="../assets/img/team/team7.png" alt="team" class="img-fluid rounded-circle">
                        </div>
                        <div class="media-body">
                          <h3 class="title">Steven Smith</h3>
                          <div class="item-ctg">Contact Lens</div>
                          <router-link to="/doctor-detail" class="item-btn btn-fill size-xs radius-4">View
                            Profile</router-link>
                        </div>
                      </div>
                      <div class="item-ctg">Contact Lens</div>
                      <div class="item-time">08.00am - 09.00am</div>
                      <div class="item-team">Dr.Mike Hussy</div>
                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">

                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">

                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">
                      <div class="media">
                        <div class="item-img">
                          <img src="../assets/img/team/team7.png" alt="team" class="img-fluid rounded-circle">
                        </div>
                        <div class="media-body">
                          <h3 class="title">Steven Smith</h3>
                          <div class="item-ctg">Contact Lens</div>
                          <router-link to="/doctor-detail" class="item-btn btn-fill size-xs radius-4">View
                            Profile</router-link>
                        </div>
                      </div>
                      <div class="item-ctg">Contact Lens</div>
                      <div class="item-time">08.00am - 09.00am</div>
                      <div class="item-team">Dr.Mike Hussy</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <div class="schedule-time-wrapper">04.00pm</div>
                  </th>
                  <td>
                    <div class="schedule-item-wrapper">

                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">

                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">
                      <div class="media">
                        <div class="item-img">
                          <img src="../assets/img/team/team7.png" alt="team" class="img-fluid rounded-circle">
                        </div>
                        <div class="media-body">
                          <h3 class="title">Steven Smith</h3>
                          <div class="item-ctg">Contact Lens</div>
                          <router-link to="/doctor-detail" class="item-btn btn-fill size-xs radius-4">View
                            Profile</router-link>
                        </div>
                      </div>
                      <div class="item-ctg">Contact Lens</div>
                      <div class="item-time">08.00am - 09.00am</div>
                      <div class="item-team">Dr.Mike Hussy</div>
                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">
                      <div class="media">
                        <div class="item-img">
                          <img src="../assets/img/team/team7.png" alt="team" class="img-fluid rounded-circle">
                        </div>
                        <div class="media-body">
                          <h3 class="title">Steven Smith</h3>
                          <div class="item-ctg">Contact Lens</div>
                          <router-link to="/doctor-detail" class="item-btn btn-fill size-xs radius-4">View
                            Profile</router-link>
                        </div>
                      </div>
                      <div class="item-ctg">Contact Lens</div>
                      <div class="item-time">08.00am - 09.00am</div>
                      <div class="item-team">Dr.Mike Hussy</div>
                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">

                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">

                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">
                      <div class="media">
                        <div class="item-img">
                          <img src="../assets/img/team/team7.png" alt="team" class="img-fluid rounded-circle">
                        </div>
                        <div class="media-body">
                          <h3 class="title">Steven Smith</h3>
                          <div class="item-ctg">Contact Lens</div>
                          <router-link to="/doctor-detail" class="item-btn btn-fill size-xs radius-4">View
                            Profile</router-link>
                        </div>
                      </div>
                      <div class="item-ctg">Contact Lens</div>
                      <div class="item-time">08.00am - 09.00am</div>
                      <div class="item-team">Dr.Mike Hussy</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <div class="schedule-time-wrapper">06.00pm</div>
                  </th>
                  <td>
                    <div class="schedule-item-wrapper">
                      <div class="media">
                        <div class="item-img">
                          <img src="../assets/img/team/team7.png" alt="team" class="img-fluid rounded-circle">
                        </div>
                        <div class="media-body">
                          <h3 class="title">Steven Smith</h3>
                          <div class="item-ctg">Contact Lens</div>
                          <router-link to="/doctor-detail" class="item-btn btn-fill size-xs radius-4">View
                            Profile</router-link>
                        </div>
                      </div>
                      <div class="item-ctg">Contact Lens</div>
                      <div class="item-time">08.00am - 09.00am</div>
                      <div class="item-team">Dr.Mike Hussy</div>
                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">

                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">
                      <div class="media">
                        <div class="item-img">
                          <img src="../assets/img/team/team7.png" alt="team" class="img-fluid rounded-circle">
                        </div>
                        <div class="media-body">
                          <h3 class="title">Steven Smith</h3>
                          <div class="item-ctg">Contact Lens</div>
                          <router-link to="/doctor-detail" class="item-btn btn-fill size-xs radius-4">View
                            Profile</router-link>
                        </div>
                      </div>
                      <div class="item-ctg">Contact Lens</div>
                      <div class="item-time">08.00am - 09.00am</div>
                      <div class="item-team">Dr.Mike Hussy</div>
                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">

                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">
                      <div class="media">
                        <div class="item-img">
                          <img src="../assets/img/team/team7.png" alt="team" class="img-fluid rounded-circle">
                        </div>
                        <div class="media-body">
                          <h3 class="title">Steven Smith</h3>
                          <div class="item-ctg">Contact Lens</div>
                          <router-link to="/doctor-detail" class="item-btn btn-fill size-xs radius-4">View
                            Profile</router-link>
                        </div>
                      </div>
                      <div class="item-ctg">Contact Lens</div>
                      <div class="item-time">08.00am - 09.00am</div>
                      <div class="item-team">Dr.Mike Hussy</div>
                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">

                    </div>
                  </td>
                  <td>
                    <div class="schedule-item-wrapper">

                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>-->
  <!-- Schedule Area End Here -->

</template>
<script>
  import HomeSlider from './HomeSlider'
  import axios from 'axios'
  import swal2 from 'sweetalert2'

 // import { defineComponent } from 'vue'
  import { Carousel, Navigation, Slide } from 'vue3-carousel/dist/carousel.es'
  import 'vue3-carousel/dist/carousel.css'
  //import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
  export default {
    name: 'HomeComponent',
    components: { HomeSlider , Carousel,
      Slide,
      Navigation,},
    data () {
      return {
        welcomeData:[],
        specializeData:[],
        features:[],
        highRateDoctors:[],
        currentSlide: 0,
        currentSlideDoctor: 0,
      }
    },
    methods: {
      getWelcomeData:  async function () {
        const response = await axios.get(this.$apiUrl + 'getWelcomeData', null);
        if (response.data.status == true) {

          this.welcomeData= response.data.data

        } else {
          swal2.fire("error", response.data.message, "error");

        }
      },
     getSpecialize:async function () {
        const response = await axios.get(this.$apiUrl + 'specialize/last-spcializations/all/spec_only', null);
        if (response.data.status == true) {

          this.specializeData= response.data.data


        } else {
          swal2.fire("error", response.data.message, "error");

        }
      },
      getFeatures:async function () {
        const response = await axios.get(this.$apiUrl + 'getFeatures?call_key=features', null);
        if (response.data.status == true) {

          this.features= response.data.data


        } else {
          swal2.fire("error", response.data.message, "error");

        }
      },
      highRateDoctors_:async function () {
        const response = await axios.get(this.$apiUrl + 'doctor/list?limit=8', {
          headers: {
            'Accept-Language': this.locale,
          }
        });
        if (response.data.status == true) {

          this.highRateDoctors= response.data.data.data


        } else {
          swal2.fire("error", response.data.message, "error");

        }
      },
      next() {
        this.$refs.carousel.next()
      },
      prev() {
        this.$refs.carousel.prev()
      },
  },
    mounted:function(){
      this.getWelcomeData()
      this.getSpecialize()
      this.getFeatures()
      this.highRateDoctors_()
    }
  }

</script>
<style>
  .h_rate_doctors_desc{
    height: 50px;
    overflow-wrap: unset;
    overflow: auto;

  }
  .h_rate_doctors_title{
    height: 75px;
    overflow-wrap: unset;
    overflow: hidden;
  }
  li.carousel__slide.carousel__slide--clone{
    padding: 2rem !important;
  }
</style>
