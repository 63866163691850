<template>

  <!-- Slider Area Start Here -->
  <div class="slider-area slider-layout1 bg-light-primary slider-top-margin">
    <div class="bend niceties preview-1">
     <!-- <div id="ensign-nivoslider-1" class="slides">-->
        <carousel :items-to-show="1" :autoplay="3000" :wrap-around="true" :dir="rtl"  >
          <slide  v-for="(slider,index) in sliderData" :key="index" >
            <div id="ensign-nivoslider-1" class="slides">
            <img  :src="$attachUrl +'images/'+ slider.img_path" v-bind:alt="'slider'+index"  v-bind:title="'#slider-direction-'+index" />
            </div>
            <div v-bind:id="'slider-direction-'+index" class="t-cn slider-direction" ><!--class="t-cn slider-direction"-->
              <div class="slider-content s-tb slide-1">
                <div class="text-left title-container s-tb-c">
                  <div class="container">
                    <div v-if="locale=='en'" class="slider-big-text padding-right">{{slider.head_title_en}}</div>
                    <div v-else class="slider-big-text padding-right">{{(slider.head_title_ar).replace(/<[^>]+>/g, '')}}</div>
                    <p v-if="locale=='en'" class="slider-paragraph padding-right">{{(slider.sub_title_en).replace(/<[^>]+>/g, '')}}</p>
                    <p v-else class="slider-paragraph padding-right">{{(slider.sub_title_ar).replace(/<[^>]+>/g, '')}}</p>
                    <div class="slider-btn-area" style="clear: both;">
                      <a href="#" class="item-btn"><span v-if="locale=='en'" style="font:bold">Read More<i class="fas fa-chevron-left"></i></span><span v-else>  قرأة المزيد <i class="fas fa-chevron-right"></i></span></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </slide>
        </carousel>
     <!-- </div>-->

    </div>
  </div>
  <!-- Slider Area End Here -->
</template>
<script>
  import axios from 'axios'
  import swal2 from 'sweetalert2'
  import 'vue3-carousel/dist/carousel.css'
  import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
    export default {
      components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
      },
        name:'home-slider',
      data(){
         return{
           sliderData:[],
           loaded:false
         }
      },
        methods:{
          getSliderData: async  function(){
            const response=await axios.get(this.$apiUrl+"getSliderData" ,null)
            if(response.data.status==true){

                this.sliderData=response.data.data
                 console.log(this.sliderData)


            }else{
                  swal2.fire("error" ,response.data.message,"error");

             }
          }
        },
      mounted:function(){
          this.getSliderData()
        if(this.sliderData!== null) {
          this.loaded = true;
        }

      }
    }
</script>
