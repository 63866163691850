<template>
    <!-- Footer Area Start Here -->
    <footer>
        <section class="footer-top-wrap">
            <div class="container">
                <div class="row">
                    <div class="single-item col-lg-3 col-md-6 col-12">
                        <div class="footer-box">
                            <div class="footer-logo">
                                <router-link to="/"><img v-bind:src="this.$attachUrl+'images/'+this.welcomeData.logo_light" class="img-fluid" alt="footer-logo"></router-link>
                            </div>
                            <div class="footer-about">
                                <p>{{welcomeData.description}}
                                </p>
                            </div>
                            <div class="footer-contact-info">
                                <ul>
                                    <!--<li><i class="fas fa-map-marker-alt"></i>59 Street, 1200 Techpark</li>-->
                                    <li><i class="fas fa-phone"></i>{{welcomeData.phone}}</li>
                                    <li><i class="far fa-envelope"></i>{{welcomeData.email}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="single-item col-lg-3 col-md-6 col-12">
                        <div class="footer-box">
                            <div class="footer-header">
                                <h3 v-if="locale=='en'">Departments</h3>
                                <h3 v-else>التخصصات</h3>
                            </div>
                            <div class="footer-departments">
                                <ul>
                                    <li v-for="(special,spec_index) in specializeData">
                                      <router-link to="/doctor?specialization_id=special.id">{{special.name}}</router-link>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="single-item col-lg-3 col-md-6 col-12">
                        <div class="footer-box">
                            <div class="footer-header">
                                <h3 v-if="locale=='en'">Quick Links</h3>
                                <h3 v-else>روابط أخري</h3>
                            </div>
                            <div class="footer-quick-link">
                                <ul>
                                  <li><router-link to="/about"><span v-if="locale=='en'">About Us</span><span v-else> نبذه عنا</span></router-link></li>
                                    <li><router-link to="/doctor"><span v-if="locale=='en'">Doctors</span><span v-else> الأطباء</span></router-link></li>
                                    <li><router-link to="/specializations"><span v-if="locale=='en'">Specializations</span><span v-else>  التخصصات</span></router-link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="single-item col-lg-3 col-md-6 col-12">
                        <div class="footer-box">
                            <div class="footer-header">
                                <h3 v-if="locale=='en'">Opening Hours</h3>
                                <h3 v-else>ساعات العمل</h3>
                            </div>
                            <div class="footer-opening-hours">
                                <ul>
                                    <li v-if="locale=='en'">Opening 24 Hours</li>
                                    <li v-else>معاك 24 ساعه</li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="footer-center-wrap">
            <div class="container">
                <div class="row no-gutters">
                    <div class="col-lg-4 col-12">
                        <div class="footer-social">
                            <ul>
                              <li ><span v-if="locale=='en'">Follow Us:</span> <span v-else> يمكنك متابعتنا:</span></li>
                                <li><router-link to="/fb"><i class="fab fa-facebook-f"></i></router-link></li>
                                <li><router-link to="/twitter"><i class="fab fa-twitter"></i></router-link></li>
                                <li><router-link to="/instgram"><i class="fab fa-brands fa-instagram"></i></router-link></li>
                                <li><router-link to="/app_store"><i class="fab fa-google-play"></i></router-link></li>
                                <li><router-link to="/apple_store"><i class="fab fa-apple"></i></router-link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-8 col-12">
                        <div class="row">
                            <div class="col-md-6 col-12">
                                <div class="newsletter-title">
                                    <h4 class="item-title">Stay informed and healthy</h4>
                                </div>
                            </div>
                            <div class="col-md-6 col-12">
                                <div class="newsletter-form">
                                    <div class="input-group stylish-input-group">
                                        <input type="text" class="form-control" placeholder="Enter your e-mail">
                                        <span class="input-group-addon">
                                                <button type="submit">
                                                    <span aria-hidden="true">SIGN UP!</span>
                                                </button>
                                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </footer>
    <!-- Footer Area End Here -->
</template>
<script>


  import  "../../assets/js/plugins.js"
  import "../../../node_modules/popper.js/dist/umd/popper.min.js"
  import "../../../node_modules/bootstrap/dist/js/bootstrap"
  import  "../../assets/js/jquery.counterup.min.js"
  import "../../assets/js/parallaxie.js"
  import  "../../assets/vendor/slider/js/jquery.nivo.slider.js"
  import  "../../assets/vendor/slider/home.js"

  import "../../assets/js/jquery.magnific-popup.min.js"
  import "../../assets/js/smoothscroll.min.js"

  import "../../assets/js/jquery.meanmenu.min"
  import "../../assets/js/main.js"
  import "../../assets/vendor/slick/slick"
  import jQuery from "jquery"



 // import "../../assets/js/waypoints.min.js"



//  import "../../../node_modules/owl.carousel/dist/owl.carousel.min.js"


  //import "../../assets/js/isotope.pkgd.min.js"


  import isotope from 'vueisotope'



  import axios from 'axios'
  import swal2 from 'sweetalert2'
    export default {
      name:"AppFooter",

      data () {
        return {
          specializeData:[],
          welcomeData:[],
          welcomeData_:[],
        }
      },
      methods: {
        getWelcomeData:  async function () {
          const response = await axios.get(this.$apiUrl + 'getWelcomeData',{
            headers: {
              'Accept-Language':this.locale ,
            }
          });
          if (response.data.status == true) {

            this.welcomeData= response.data.data
            this.welcomeData_= response.data.data

          } else {
            swal2.fire("error", response.data.message, "error");

          }
        },
        getSpecialize:async function () {
          const response = await axios.get(this.$apiUrl + 'specialize/last-spcializations/all/spec_only',{
            headers: {
              'Accept-Language':this.locale ,
            }
          });
          if (response.data.status == true) {

            this.specializeData= response.data.data


          } else {
            swal2.fire("error", response.data.message, "error");

          }
        }
        },
         mounted:function(){
           this.getWelcomeData()
          this.getSpecialize()

        }
    }



</script>
