<template>

  <!-- Inne Page Banner Area Start Here -->
  <section class="inner-page-banner bg-common inner-page-top-margin"  :style="{backgroundImage:'url(\'' +this.$attachUrl+'images/'+this.navData.doctors_banner+ '\')'}">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="breadcrumbs-area">
            <h1 v-if="locale=='en'">  About </h1><h1 v-else>عن الموقع </h1>
            <ul>
              <li>
                <a v-if="locale=='en'" href="#">Home</a>
                <a v-else href="#">الرئيسيه</a>
              </li>
              <li v-if="locale=='en'">About</li>
              <li v-else> عن الموقع</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Inne Page Banner Area End Here -->
  <!-- Contact Us Area Start Here -->
  <section class="contact-wrap-layout">
    <div class="container">

      <div class="row">
        <div class="col-lg-8">
          <div class="contact-box-layout1">
            <h3 class="title title-bar-primary4"><span v-if="locale=='en'">Leave Us Message</span><span v-if="locale=='ar'"> اترك رسالتك</span></h3>
            <form class="contact-form-box" id="contact-form">
              <div class="row">
                <div class="col-md-6 form-group">
                  <input type="text" placeholder="Full Name *" class="form-control"  v-model="form.full_name" name="full_name"
                         data-error="full name field is required" required>
                  <div class="help-block with-errors"></div>
                </div>
                <div class="col-md-6 form-group">
                  <input type="text" placeholder="Phone*" class="form-control" v-model="form.phone" name="phone"
                         data-error="phone field is required" required>
                  <div class="help-block with-errors"></div>
                </div>
                <div class="col-md-6 form-group">
                  <input type="email" placeholder="E-mail *" class="form-control" v-model="form.email" name="email"
                         data-error="email field is required" required>
                  <div class="help-block with-errors"></div>
                </div>

                <div class="col-12 form-group">
                                        <textarea placeholder="Message*" class="textarea form-control" v-model="form.message" name="message"
                                                  id="form-message" rows="7" cols="20" data-error="Message field is required"
                                                  required></textarea>
                  <div class="help-block with-errors"></div>
                </div>
                <div class="col-12 form-group margin-b-none">
                  <button type="submit"   @click.stop.prevent="submitMessage()" class="item-btn">Submit Message</button>
                </div>
              </div>
              <div class="form-response"></div>
            </form>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="contact-box-layout1">
            <h3 class="title title-bar-primary4"><span v-if="locale=='en'">Contact us</span><span v-if="locale=='ar'">تواصل معانا</span></h3>
            <div class="contact-info">
              <ul>
               <!-- <li><i class="fas fa-map-marker-alt"></i>Medilink Technological Limited</li>-->
                <li><i class="far fa-envelope"></i>{{navData.email}}</li>
                <li><i class="fas fa-phone"></i>{{navData.phone}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Contact Us Area End Here -->
</template>
<script>
  // @ is an alias to /src
  import axios from 'axios'
  import swal2 from 'sweetalert2'
  export default {
    name: 'ContactView',
    props:{navData:Array,specializeData:Array},
    data(){
      return{
        form: {
          full_name:"",
          phone: "",
          email: "",
          message: "",

        },
        errors: [],
        message_error:""

      }
    },
    methods: {
      submitMessage: async function () {
        //  const response =
        axios.post(this.$apiUrl + "message/create",this.form,{

          headers: {
            'Accept-Language': this.locale,
          }
        })
          .then((res) => {
            console.log(res)
            swal2.fire("done", "", "success").then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                close()
                this.$router.push({ name: 'contact', query: { redirect: '/contact'} });
              } else if (result.isDenied) {
                Swal.fire('error when save', '', 'info')
              }
            });
          })
          .catch((error) => {
            console.log(error)

          }).finally(() => {
          //Perform action in always
        });
        /* console.log(response)
         console.log(response.status)
        if (response.code == 200) {

          this.$router.router.back()
        } else if(response.code == 422 ) {
          swal2.fire("error", response.data.message, "error");

        }else{
         console.log("error")
        }*/
      },
    }


  }

</script>
