import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DoctorView from '../views/DoctorView.vue'
import AboutView from '../views/AboutView.vue'
import AppFooter from '@/components/layouts/AppFooter'
import SpecializationView from '../views/SpecializationView.vue'
import DoctordetailView from '../views/DoctordetailView.vue'
import ConsaltationView from '../views/ConsaltationView.vue'
import ConsalationDetailView from '../views/ConsalationDetailView.vue'
import LoginView from '../views/LoginView.vue'
import SignupView from '@/views/SignupView'
import ContactView from '@/views/ContactView'

const routes = [
  {
    path: '/',
    name: '/',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AboutView
  },
  {
    path: '/contact',
    name: 'contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ContactView
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: LoginView
  },
  {
    path: '/doctor',
    name: 'doctor',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: DoctorView
  },
  {
    path:  '/doctor/:doctor_name',
    name: 'doctorSearch',
    redirect: to => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: '/doctor', query: { doctor_name: to.params.doctor_name} }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: DoctorView
  },
  {
    path: '/doctor/:doctorId',
    name: 'doctor_details',
    component: DoctordetailView,
    props: true,
  },

  {
    path: '/specialization',
    name: 'specialization',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component:SpecializationView
  },
  {
    path: '/myconsaltations',
    name: 'myconsaltations',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component:ConsaltationView
  },
  {
    path: '/consaltation_details/:consaltId',
    name: 'consaltation_details',
    component:ConsalationDetailView,
    props: true,
  },
  {
    path: '/signup',
    name: 'signup',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component:SignupView
  },
  {
    path: '/fb',
    beforeEnter() {location.href = 'http://github.com'},
    component:  AppFooter
  },
  {
    path: '/twitter',
    beforeEnter() {location.href = 'http://github.com'},
    component: AppFooter
  }
  ,	{
    path: '/instgram',
    beforeEnter() {location.href = 'http://github.com'},
    component:  AppFooter
  },
  {
    path: '/app_store',
    beforeEnter() {location.href = 'http://github.com'},
    component:  AppFooter
  },
  {
    path: '/apple_store',
    beforeEnter() {location.href = 'http://github.com'},
    component: AppFooter
  },
  {
    path: '/apple_store',
    beforeEnter() {location.href = 'http://github.com'},
    component: AppFooter
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
