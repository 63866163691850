<template>
  <!-- Inne Page Banner Area Start Here -->
  <section class="inner-page-banner bg-common inner-page-top-margin" :style="{backgroundImage:'url(\'' +this.$attachUrl+'images/'+this.navData.doctors_banner+ '\')'}">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="breadcrumbs-area">
            <h1 v-if="locale=='en'">All Doctors</h1><h1 v-else> كل الأطباء</h1>
            <ul>
              <li>
                <a v-if="locale=='en'" href="#">Home</a>
                <a v-else href="#">الرئيسيه</a>
              </li>
              <li v-if="locale=='en'">Doctors</li>
              <li v-else>الأطباء</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Inne Page Banner Area End Here -->
  <!-- All Doctors Start Here -->
  <section class="team-wrap-layout3 bg-light-accent100">
    <div class="container">
      <div class="team-search-box">
        <form  method="get">
          <div class="row">
            <div class="col-xl-4">

              <div class="form-group">

                <select  v-model="search_form.specialize" class="form-select" name="specialize">
                 <option value="0"><span v-if="locale=='en'">Select Department</span><span v-else>اختر تخصص</span></option>
                  <option v-for="(special,index) in specializeData"
                          :value="special.id"
                          :key="index">
                    {{special.name}}
                  </option>

                </select>
              </div>
            </div>
            <div  class="col-xl-6">
              <div class="form-group">
                <input class="doctor-name form-control" type="text" placeholder="Type Doctor Name Here ..."
                       name="doctor-name" v-model="search_form.doctor_name"   >
              </div>
            </div>
            <div class="col-xl-2">
              <div class="form-group">
                <button type="submit" @click.prevent="searchDoctor()" class="item-btn"><span v-if="locale=='en'">Search</span><span v-else>بحث</span></button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="row">
        <div v-for="(doctor,index) in highRateDoctors"  class="col-lg-6 col-md-6">
          <div class="team-box-layout1">
            <div class="media media-none-lg media-none-md media-none--xs">
              <div class="item-img">
                <img v-bind:src="doctor.avatar_url"   @error='$event.target.src=$attachUrl+"images/users/profile.jpg"'  :alt="doctor.name" class="img-fluid rounded-circle media-img-auto">
              </div>
              <div class="media-body">
                <div class="item-content">
                  <h4 class="item-title">
                    <router-link :to="{ name: 'doctor_details', params: { doctorId: doctor.id }}">{{doctor.name}}{{doctor.last_name}}</router-link>
                  </h4>
                  <p class="designation">{{doctor.specialize.name}}</p>
                  <div class="item-degree">{{doctor.work_details}}</div>
                  <ul class="item-btns">
                    <li>
                      <router-link :to="{ name: 'doctor_details', params: { doctorId: doctor.id }}" class="item-btn btn-ghost"><span v-if="locale=='en'">View Profile</span> <span v-else> مشاهدة التفاصيل</span></router-link>

                    </li>
                    <li>
                      <router-link :to="{ name: 'doctor_details', params: { doctorId: doctor.id }}" class="item-btn btn-fill"><span v-if="locale=='en'">Make an
                        Appointment</span> <span v-else> حجز ميعاد</span></router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

     <!-- <ul class="pagination-layout2 margin-t-50r">
        <li>
          <a href="#">Previous</a>
        </li>
        <li class="active">
          <a href="#">1</a>
        </li>
        <li>
          <a href="#">2</a>
        </li>
        <li>
          <a href="#">3</a>
        </li>
        <li>
          <a href="#">Next</a>
        </li>
      </ul>-->
      <Pagination v-if="listItems" :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />
    </div>
  </section>
  <!-- All Doctors End Here -->
</template>
<script>
  import axios from 'axios'
  import swal2 from 'sweetalert2'
  import Pagination from '../components/Pagination'
  import Loader from '../components/Loader'

  export default {
    components: {
      Pagination,
      Loader
    },
    name:'Doctors',
    props:{navData:Array,specializeData:Array},
    data(){
      return{
        special_id:(this.$router.currentRoute._value.query.specialization_id>0)?this.$router.currentRoute._value.query.specialization_id:"",
        doctors:[],
        highRateDoctors:[],
        page: 1,
       // welcomeData_:this.welcomeData,
        search_form: {
          specialization_id:"",
          doctor_name: "",
          page:this.page,
          per_page:2
        },
        search_data:[],
       // page:1,

        showLoader: false,
        listItems: [],

        paginate:{perpage:2,page:this.page},
        totalPages: 0,
        totalRecords: 0,
        recordsPerPage: 10,
        enterpageno: ''


      }
    },
    created () {
      this.getDoctors()
/*console.log("kkk");
      console.log(this.$route.query.doctor_name);
     // this.searchDoctor()*/
    },
    methods:{
      getDoctors: async  function(){
        this.showLoader = true
        const response=await axios.get(this.$apiUrl+"doctor/list" ,{
          params:this.paginate,
          headers: {
            'Accept-Language':this.locale ,
          }
        })
        if(response.data.status==true){

          this.sliderData=response.data.data.data


        }else{
          swal2.fire("error" ,response.data.message,"error");

        }
      },
      highRateDoctors_:async function () {

    const response = await axios.get(this.$apiUrl + 'doctor/list', {
      params:{
        specialization_id:this.special_id,
        doctor_name: "",
        page:this.page,
        per_page:2
      }/*{page:this.page}*/,//this.paginate,
      headers: {
        'Accept-Language': this.locale,
      }
    });
    if (response.data.status == true) {
      this.showLoader = false
      this.highRateDoctors= response.data.data.data
      this.totalPages = response.data.data.meta.last_page // Calculate total records
      this.totalRecords = response.data.data.meta.last_page*response.data.data.meta.per_page
    } else {
      swal2.fire("error", response.data.message, "error");

    }
  },
      searchDoctor:async function(){
        console.log(this.search_form)
        const response = await axios.get(this.$apiUrl + 'doctor/list', {
          params:{
            specialization_id:this.search_form.specialization_id,
            doctor_name: this.search_form.doctor_name,
            page:this.page,
            per_page:2
          },
          headers: {
            'Accept-Language': this.locale,
          }
        });
        console.log(response)
        if (response.data.status == true) {


          this.highRateDoctors= response.data.data.data


        } else {
          swal2.fire("error", response.data.message, "error");

        }
      },
      onPageChange (page) {
        this.page = page
        console.log(this.page);
        this.highRateDoctors_()
      },
      onChangeRecordsPerPage () {
        this.highRateDoctors_()
      },
      gotoPage () {
        if (!isNaN(parseInt(this.enterpageno))) {
          this.page = parseInt(this.enterpageno)
          this.highRateDoctors_()
        }
      }
    },
    mounted:function(){
      //this.getWelcomeData()
      this.getDoctors()
      this.highRateDoctors_()

      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri);
   console.log("gggg");
      console.log(params)
      this.search_form=params
      if(params!=""){
        this.searchDoctor()
      }
    }
  }
</script>
<style>
  img.img-fluid.rounded-circle.media-img-auto{width: 13em;height:13em}


  h2 {
    text-align: center;
  }
  ul li {
    list-style-type: none;
  }
  ul.flex-container {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-direction: row wrap;
    flex-wrap: wrap;
    justify-content: space-around;
  .flex-item {
    background: tomato;
    width: calc(100% / 5.5);
    padding: 5px;
    height: auto;
    margin-top: 10px;
    color: white;
    font-weight: bold;
    text-align: center;
  }
  img {
    display: initial;
    width: 200px;
  }
  }
  .showItems {
    display: inline-block;
    margin-left: -35px;
  li {
    list-style-type: none;
    display: inline-block;
    margin-left: 10px;
  }
  }
</style>
