<template>

  <!-- Inne Page Banner Area Start Here -->
  <section class="inner-page-banner bg-common inner-page-top-margin"  :style="{backgroundImage:'url(\'' +this.$attachUrl+'images/'+this.navData.doctors_banner+ '\')'}">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="breadcrumbs-area">
            <h1 v-if="locale=='en'">Doctor Details</h1><h1 v-else>تفاصيل الطبيب</h1>
            <ul>
              <li>
                <a v-if="locale=='en'" href="#">Home</a>
                <a v-else href="#">الرئيسيه</a>
              </li>
              <li v-if="locale=='en'">Doctors</li>
              <li v-else>تفاصيل الطبيب</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Inne Page Banner Area End Here -->

  <!-- Doctors Detail Start Here -->
  <section class="team-details-wrap-layout1 bg-light-accent100">
    <div class="container">
      <div class="row">
        <div class="order-xl-2 order-lg-2 col-xl-9 col-lg-8 col-md-12 col-12">
          <div class="team-detail-box-layout1">
            <div class="single-item">
              <h3 class="section-title title-bar-primary2"><span v-if="locale=='en'">About Me:</span><span v-else>نبذه عن الطبيب </span></h3>
              <p>{{doctorDetail.work_details}}</p>
            </div>
            <div class="single-item">
              <div class="table-responsive">
                <h3 class="section-title title-bar-primary2"><span v-if="locale=='en'">Education:</span><span v-else>التعليم:</span></h3>
                <table class="table">
                  <thead>
                  <tr>
                    <th><span v-if="locale=='en'">Year</span><span if-else> السنه</span></th>
                    <th><span v-if="locale=='en'">Degree</span> <span if-else>الدرجه</span></th>
                    <th><span v-if="locale=='en'">Institute</span><span if-else> المعهد/الجامعه</span></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(certificate) in doctorDetail.certificate_doctor">
                    <td>{{certificate.year}}</td>
                    <td>{{certificate.degree}}</td>
                    <td>{{certificate.collage}}{{certificate.university}}</td>
                  </tr>

                  </tbody>
                </table>
              </div>
            </div>

            <div class="single-item">
              <div class="table-responsive">
                <h3 class="section-title title-bar-primary2"><span v-if="locale=='en'">Appointmnet Schedules:</span><span if-else> المواعيد :</span></h3>
                <table class="table schedule-table">
                  <thead>
                  <tr>
                    <th><span v-if="locale=='en'">Day</span><span v-else>اليوم</span></th>
                    <th><span v-if="locale=='en'">Time</span><span v-else>الوقت</span></th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(appoints) in doctorAppoint">
                    <td>{{appoints.day_name}}  &nbsp; &nbsp; &nbsp; <br/>  {{appoints.date}}</td>
                   <!--<td v-if="doctorDetail.appoint_type==0"></td>-->
                    <td  :disabled="appoints.day_apppionts.status != true">{{appoints.day_apppionts.start_time}} - {{appoints.day_apppionts.end_time}}</td>
                    <td  :disabled="appoints.day_apppionts.status != true || appoints.day_apppionts=={}" class="schedule-btn"> <input :disabled="appoints.day_apppionts.status != true || appoints.day_apppionts=={}"  @click="[showModal(),booking(),getQuestion()]" class="btn add-btn item-btn"  data-toggle="modal" data-target="#add_appoint"  value="+ Appointment"/></td>
                  </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="order-xl-1 order-lg-1 sidebar-widget-area sidebar-break-md col-xl-3 col-lg-4 col-md-12 col-12">
          <div class="widget widget-about-team">
            <img  v-bind:src="this.doctorDetail.avatar_url" class="img-fluid" alt="doctor">
            <div class="item-content">
              <h3 class="item-title">{{doctorDetail.name}}{{doctorDetail.last_name}}</h3>
              <p class="item-ctg">{{doctorDetail.specialize}}</p>
              <span class="item-designation">{{doctorDetail.work_details}}</span>
            </div>
          </div>
          <div class="widget widget-team-contact">
            <h3 class="section-title title-bar-primary2">Personal Info</h3>
            <ul>
              <li><span v-if="locale=='en'">Phone:</span><span v-else>رقم التليفون:</span>{{doctorDetail.phone}}</li>
              <!--<li>Office:<span>+ 88500-567</span></li>-->
              <li><span v-if="locale=='en'">E-mail:</span><span v-else> البريد الالكتروني</span>{{doctorDetail.email}}</li>
              <li class="d-flex"><span v-if="locale=='en'">Social:</span><span v-else> وسائل التواصل:</span>
                <ul class="widget-social">
                  <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                  <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                  <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                  <li><a href="#"><i class="fab fa-skype"></i></a></li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="widget widget-call-to-action">
            <div class="media">
              <img src="img/figure/figure1.png" alt="figure">
              <div class="media-body space-sm">
                <h4>Emergency Cases</h4>
                <span>2-800-700-6200</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Add Client Modal -->
      <div id="add_appoint"  v-show="isModalVisible" class="modal custom-modal fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title action-items-primary-btn item-btn"> Booking</h5>
              <button type="button" class="close" data-dismiss="modal" @click="close" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="alert alert-danger print-error-msg" style="display:none">
                <ul></ul>
              </div>
              <form action="" enctype="multipart/form-data" method="post">

                <div class="row">

                 <div class="col-md-12">
                    <div class="form-group">
                     <label class="col-form-label">about consaltation <span class="text-danger">*</span></label>
                      <textarea   v-model="message" class="form-control" type="text" name="about_consalt" rows="10"></textarea>
                    </div>
                  </div>
                  <!--<div class="col-md-12">
                    <h3>Upload your images</h3>
                    <input type="file" id="files" name="files[]" multiple />
                  </div>-->
                  <div  class="col-md-12">
                    <h3>Upload your images</h3>

                    <input type="file" @change="uploadImage" name="files[]" multiple id="image"  accept="image/*" >
                    <div class="form-group">
                      <div id="image_preview" style="width:100%;">
                        <div v-for="(image_url,index) in imagePath" class='img-div' :id='"img-div"+index'><img v-bind:src='this.$attachUrl+image_url' class='img-responsive image img-thumbnail'><div class='middle'><button id='action-icon' @click="deleteFile(index)" :value='"img-div"+index' class='btn btn-danger'><i class='fa fa-trash'></i></button></div></div><div class='clear-fix'></div>
                      </div>
                    </div>
                    <!-- <button v-on:click="open" id="upload_widget" class="cloudinary-button">
                    Upload files
                  </button>-->

                  </div>
                  <!-- Faq Content Area Start Here -->
                  <section class="faq-wrap-layout1">
                    <div class="container">
                      <div class="row">
                        <div class="col-xl-12 col-lg-12 col-12">
                          <div class="faq-content-layout1">
                            <div class="item-heading">
                              <h2 class="item-title title-bar-primary4">Frequently Ask Any Question</h2>
                              <p class="sub-title">Have you any kind of question, please feel free ask us.</p>
                            </div>
                            <div class="faq-list-layout1">
                              <div class="panel-group" id="accordion">
                                <div v-for="(const_question_data,index_quesin) in consalt_question_array" class="panel panel-default">
                                  <div class="panel-heading active">
                                    <div class="panel-title">
                                      <a aria-expanded="false" :class="'accordion-toggle'+ 'text-row-'+index_quesin" data-toggle="collapse" data-parent="#accordion" href="#collapseOne">{{ const_question_data.question_title}}</a>
                                       <input type="hidden" :value="const_question_data.tepo"/>
                                    </div>
                                  </div>
                                  <div aria-expanded="false" id="collapseOne" :role="'tabpanel'+index_quesin" class="panel-collapse collapse show">
                                    <div class="panel-body">
                                      <div>
                                        <div v-if="const_question_data.type=='t_f'" v-for="(answer,key) in const_question_data.answers">
                                          <input   type="radio" :name="'answer'+const_question_data.id"  :value="answer.id"/><span>{{answer.title}}</span>

                                        </div>
                                        <div  v-if="const_question_data.type=='choose'" v-for="(answer,key) in const_question_data.answers">
                                          <input  v-if="const_question_data.type=='choose'" type="checkbox" :name="'answer'+const_question_data.id"  :value="answer.id"/><span>{{answer.title}}</span>

                                        </div>
                                        <div v-if="const_question_data.type=='text'" >
                                          <textarea :name="'answer'+const_question_data.id"></textarea>
                                        </div>

                                        <div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <!--<div class="panel panel-default">
                                  <div class="panel-heading">
                                    <div class="panel-title">
                                      <a aria-expanded="false" class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">What if I don’t have insurance</a>
                                    </div>
                                  </div>
                                  <div aria-expanded="false" id="collapseTwo" role="tabpanel" class="panel-collapse collapse">
                                    <div class="panel-body">
                                      <p>Moimply dummy text of the printing and type settingaindustry. Lorem Ipsum
                                        has been the industry’s standard dummy text ever since thelong established
                                        fact thaaret </p>
                                    </div>
                                  </div>
                                </div>
                                <div class="panel panel-default">
                                  <div class="panel-heading">
                                    <div class="panel-title">
                                      <a aria-expanded="false" class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree">Vitae nulla bibendum molestie liquet?</a>
                                    </div>
                                  </div>
                                  <div aria-expanded="false" id="collapseThree" role="tabpanel" class="panel-collapse collapse">
                                    <div class="panel-body">
                                      <p>Moimply dummy text of the printing and type settingaindustry. Lorem Ipsum
                                        has been the industry’s standard dummy text ever since thelong established
                                        fact thaaret </p>
                                    </div>
                                  </div>
                                </div>
                                <div class="panel panel-default">
                                  <div class="panel-heading">
                                    <div class="panel-title">
                                      <a aria-expanded="false" class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFour">Accepting Insurance Cards</a>
                                    </div>
                                  </div>
                                  <div aria-expanded="false" id="collapseFour" role="tabpanel" class="panel-collapse collapse">
                                    <div class="panel-body">
                                      <p>Moimply dummy text of the printing and type settingaindustry. Lorem Ipsum
                                        has been the industry’s standard dummy text ever since thelong established
                                        fact thaaret </p>
                                    </div>
                                  </div>
                                </div>
                                <div class="panel panel-default">
                                  <div class="panel-heading">
                                    <div class="panel-title">
                                      <a aria-expanded="false" class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFive">Accepting Insurance Cards</a>
                                    </div>
                                  </div>
                                  <div aria-expanded="false" id="collapseFive" role="tabpanel" class="panel-collapse collapse">
                                    <div class="panel-body">
                                      <p>Moimply dummy text of the printing and type settingaindustry. Lorem Ipsum
                                        has been the industry’s standard dummy text ever since thelong established
                                        fact thaaret </p>
                                    </div>
                                  </div>
                                </div>-->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <!-- Faq Content Area End Here -->


                </div>

                <div class="submit-section">
                  <button class="btn btn-primary submit-btn" @click.prevent="saveForm()" type="add">submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /Add Client Modal -->

    </div>
  </section>
  <!-- Doctors Detail End Here -->
</template>
<script>
  import axios from 'axios'
  import swal2 from 'sweetalert2'
  import { mapState } from 'vuex'
  import { mapGetters } from "vuex";
  const myWidget = cloudinary.createUploadWidget(
    {
     //cloudName : "hzxyensd5", // replace with your own cloud name
     // uploadPreset: "aoh4fpwm"
  // cropping: true, //add a cropping step
      // showAdvancedOptions: true,  //add advanced options (public_id and tag)
      // sources: [ "local", "url"], // restrict the upload sources to URL and local files
      // multiple: false,  //restrict upload to a single file
      // folder: "user_images", //upload files to the specified folder
      // tags: ["users", "profile"], //add the given tags to the uploaded files
      // context: {alt: "user_uploaded"}, //add the given context data to the uploaded files
      // clientAllowedFormats: ["images"], //restrict uploading to image files only
      // maxImageFileSize: 2000000,  //restrict file size to less than 2MB
      // maxImageWidth: 2000, //Scales the image down to a width of 2000 pixels before uploading
      // theme: "purple", //change to a purple theme

    },
    (error, result) => {
      if (!error && result && result.event === "success") {
        console.log("Done! Here is the image info: ", result.info);
        document
          .getElementById("uploadedimage")
          .setAttribute("src", result.info.secure_url);
      }
      console.log("Done ", result.info);
    }
  );
  export default {
    components: {

    },
    name:'Doctors',

    props:{navData:Array,specializeData:Array,doctorId:String, msg: String},
    data(){
      return{
        doctorDetail:[],
        doctorAppoint:[],
        checkLogin:false,
        imagePath:[],
        consaltData:{
          doctor_id:this.doctorId,
          spacialization_id:"",
          type:1

        },
        message:"",
        messageData:{
          message:"",
          to:"",
          consalt_id:"",
          imagePath:[],

        },
        spec_doc_question:{
          doctor_id:"",
          start:0,
          end:10,
          special_id:""

        },
        consalt_question_array:[],
        image_indecation:[{
          index:0,
          active:false
        }],
        isModalVisible:false,

      }
    },
   /* computed: {
      ...mapState(['user', 'token'])
    },*/
    computed: {
      ...mapGetters(["isLoggedIn"]),
      ...mapState(['user', 'token'])
    },
    methods: {
      getDoctorDetail: async function () {
        const response = await axios.get(this.$apiUrl + "doctor/get-doctor/" + this.doctorId, {
          headers: {
            'Accept-Language': this.locale,
          }
        })
        if (response.data.status == true) {

          this.doctorDetail = response.data.data
        } else {
          swal2.fire("error", response.data.message, "error");

        }
      },
      getAppoint: async function () {
        const response = await axios.get(this.$apiUrl + "doctor/get-doctor-appoint?doctor_id=" + this.doctorId, {
          headers: {
            'Accept-Language': this.locale,
          }
        })
        if (response.data.status == true) {

          this.doctorAppoint = response.data.data
        } else {
          swal2.fire("error", response.data.message, "error");

        }
      },
      checkLogin: async function () {
        if (this.userToken == "") {
          this.checkLogin = false
        } else {
          this.checkLogin = true
        }
      },
      close:function(){
        this.isModalVisible = false;
      },
      showModal:function(){
        this.isModalVisible = true;
      },
      booking:async function () {
        //console.log(this.userToken)
        console.log(this.user)
        //if (this.userToken == "") {

        if(! this.isLoggedIn){
          console.log("check false")
          this.checkLogin = false
        //  this.$router.push({path: '/login'});
          this.$router.push({ name: 'login', query: { redirect: '/' } });
        } else {
          console.log("check true")
          this.checkLogin = true
        }
      },
      saveForm: async function () {
        console.log(this.doctorDetail);
       this.consaltData={
          doctor_id:this.doctorId,
          spacialization_id:this.doctorDetail.specialize.id,
          type:1
        }
        const response_addConsalt = await axios.post(this.$apiUrl + "consulation/add-consalt" , this.consaltData, {
          headers: {
            'Accept-Language': this.locale,
            'Authorization':'Bearer'+this.token
          }
        })
        if (response_addConsalt.data.status == true) {
          console.log(response_addConsalt)

         console.log("consalt_created")
          console.log(response_addConsalt.data.data.id);
          console.log(this.iimagePath);
          this.messageData={message:this.message,to:this.doctorId, consalt_id:response_addConsalt.data.data.id,imgPath:this.imagePath}
         console.log(this.messageData);
          console.log(this.imagePath);
          axios.post(this.$apiUrl + "consulation/send-message" , this.messageData, {
            headers: {
              'Accept-Language': this.locale,
              'Authorization':'Bearer'+this.token
            }
          }).then((res) => {
            swal2.fire("done", "ll", "success").then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
              close()
              } else if (result.isDenied) {
                Swal.fire('error when save', '', 'info')
              }
            });
           // answers={type:,answer_id:,question_id:}
            /*axios.post(this.$apiUrl + "specialize/add-patient-answer" , this.messageData, {
              headers: {
                'Accept-Language': this.locale,
                'Authorization':'Bearer'+this.token
              }*/
          })
        } else {
          swal2.fire("error", response.data.message, "error");

        }
      },
      /*:async function () {
        const response = await axios.post(this.$apiUrl + "consulation/add-consalt" , this.consaltData, {
          headers: {
            'Accept-Language': this.locale,
          }
        })
        if (response.data.status == true) {

          console.log("consalt_created")
        } else {
          swal2.fire("error", response.data.message, "error");

        }
      }*/
     /* uploadFile: async function () {
        const response = await axios.get(this.$apiUrl + "doctor/get-doctor/" + this.doctorId, {
          headers: {
            'Accept-Language': this.locale,
          }
        })
        if (response.data.status == true) {

          this.doctorDetail = response.data.data
        } else {
          swal2.fire("error", response.data.message, "error");

        }
      },*/
      uploadImage (e) {
        let img = e.target.files
        console.log(img)
        let fd= new FormData()

        fd.append('files', img)
        console.log(fd)
         axios.post(this.$apiUrl + 'consulation/uploadFiles', img, {
          headers: {
            'Accept-Language': this.locale,
            'Authorization':'Bearer'+this.token
          }
        })
       /* axios.post(this.$apiUrl + 'consulation/uploadFiles', fd, {
          headers: {
            'Accept-Language': this.locale,

          }
        } )*/
          .then(res => {
            this.imagePath =res.data.data
            console.log(this.imagePath)

          })
      },
      deleteImage:function (key){
       console.log("delete")
       console.log(key)
      },
      getQuestion:async function () {

        this.spec_doc_question={ doctor_id:this.doctorId, start:0, end:10,special_id:this.doctorDetail.specialize.id }
        const consalt_question= await axios.get(this.$apiUrl + "specialize/get-specification-question" , {
          params: this.spec_doc_question
         ,
          headers: {
            'Accept-Language': this.locale,
            'Authorization':'Bearer'+this.token
          }
        })
        if (consalt_question.data.status == true) {
          this.consalt_question_array=consalt_question.data.data
          console.log(this.consalt_question_array);
        }



        },


    },


    mounted:function(){
      this.getDoctorDetail()
      this.getAppoint()
    }

  }

</script>
<style>
  #add_appoint.modal-content{
    background: #d5e8f2;
  }
  #add_appoint.modal-header{
    background: #396cf0;
  }
  @media(min-width: 768px) {
    .img-div {
      position: relative;
      width: 32%;
      float:left;
      margin: 5px 10px;
    }
  }

  @media(max-width: 467px) {
    .img-div {
      position: relative;
      width: 100%;
      margin: 5px 10px;
    }
  }

  @media(min-width: 467px) {
    .img-div {
      position: relative;
      width: 45%;
      float:left;
      margin: 5px 10px;
    }
  }

  .image {
    opacity: 1;
    display: block;
    min-width:  100%;
    aspect-ratio:  3/2;
    transition: .5s ease;
    backface-visibility: hidden;
  }

  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  .img-div:hover .image {
    opacity: 0.3;
  }

  .img-div:hover .middle {
    opacity: 1;
  }
  .img-div{
    width: 27%;
  }
</style>

