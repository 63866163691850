<template>
  <!-- Inne Page Banner Area Start Here -->
  <section class="inner-page-banner bg-common inner-page-top-margin"  :style="{backgroundImage:'url(\'' +this.$attachUrl+'images/'+this.navData.doctors_banner+ '\')'}" >
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="breadcrumbs-area">
            <h1 ><span v-if="locale=='en'">All Departments</span><span v-else> كل التخصصات</span></h1>
            <ul>
              <li>
                <router-link to="/"><span v-if="locale=='en'">Home</span><span v-else>الرئيسيه</span></router-link>
              </li>
              <li><span v-if="locale=='en'">Departments</span><span v-else>التخصصات</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Inne Page Banner Area End Here -->
  <!-- Department Start Here -->
  <section class="departments-wrap-layout5 bg-light-accent100">
    <div class="container">
      <div class="row gutters-20">

          <div v-for="(special) in specializeData" class="col-lg-4 col-md-6 col-sm-6 col-12">
            <router-link :to="'/doctor?specialization_id='+special.id" >
              <div class="departments-box-layout4">
                <div class="box-content">
                 <!-- <i class="flaticon-medical"></i>-->
                  <img :src="this.$attachUrl+'images/'+special.img"   @error='$event.target.src=$attachUrl+"images/specializations/default.jpeg"' style="height: 140px !important;"/><!--specializations/-->
                  <h3 class="item-title"><router-link to="specialization/{{special.id}}">{{special.name}}</router-link></h3>
                  <p></p>
                </div>
              </div>
            </router-link>
          </div>

      </div>
    </div>
  </section>
  <!-- Department Area End Here -->
</template>

<script>
  export default {
    components: {

    },
    name:'Doctors',
    props:{navData:Array,specializeData:Array},
    data(){
      return{
      }
    },
    methods:{

    },
    mounted:function(){

    }
  }
</script>
