<template>
  <!-- Header Area Start Here -->
  <header id="header_1">
    <div class="header-top-bar top-bar-border-bottom bg-light-primary100 d-none d-md-block">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-12 col-md-12 col-12 header-contact-layout1">
            <ul>
              <li>
                <i class="fas fa-phone"></i><span v-if="locale=='en'" >Call:</span> <span v-if="locale=='ar'" >اتصل:</span> {{navData.phone}}</li>
              <li>
                <i class="fas fa-map-marker-alt"></i><span v-if="locale=='en'" >Email:</span> <span v-if="locale=='ar'" >البريدالالكتروني:</span> {{navData.email}}</li>
              <li>
                <i class="far fa-clock"></i><span v-if="locale=='en'" >Opening 24 hours</span> <span v-if="locale=='ar'" >مفتوح دائما</span> </li>
            </ul>
          </div>
          <div class="col-xl-4 d-none d-xl-block">
            <ul class="header-social-layout1">
              <li>
                <router-link to="/fb">

                  <i class="fab fa-facebook-f"></i>
                </router-link>

              </li>
              <li>
                <router-link to="/twitter">

                  <i class="fab fa-twitter"></i>
                </router-link>

              </li>
              <li><router-link to="/instgram"><i class="fab fa-brands fa-instagram"></i></router-link></li>
              <li><router-link to="/app_store"><i class="fab fa-google-play"></i></router-link></li>
              <li><router-link to="/apple_store"><i class="fab fa-apple"></i></router-link></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="header-menu-area header-menu-layout1">
      <div class="container"  >
        <div class="row no-gutters d-flex align-items-center" >
          <div class="col-lg-2 col-md-2 logo-area-layout1">
            <router-link to="/" class="temp-logo">
              <img v-bind:src="this.$attachUrl+'images/'+this.navData.logo_light" alt="logo" class="img-fluid" style="max-width:100% !important; height: 70px;">
            </router-link>

          </div>

          <div class="col-lg-7 col-md-7 possition-static"  :style="'display:'+display">
            <div class="template-main-menu">
              <nav id="dropdown">
                <ul>
                  <li>
                    <router-link to="/"> <span v-if="locale=='en'" >Home</span> <span v-if="locale=='ar'" >الرئيسيه</span></router-link>
                    <ul class="dropdown-menu-col-1">

                    </ul>
                  </li>
                  <li>
                    <router-link to="/about"> <span v-if="locale=='en'" >About</span> <span v-if="locale=='ar'" >عن الموقع</span></router-link>
                  </li>
                  <li>
                    <router-link to="/specialization"> <span v-if="locale=='en'" >Specializations</span> <span v-if="locale=='ar'" >التخصصات</span></router-link>
                  </li>
                  <li>
                    <router-link to="/doctor"><span v-if="locale=='en'" >Doctors</span> <span v-if="locale=='ar'" >الأطباء</span></router-link>

                  </li>
                  <li>
                    <router-link to="/myconsaltations"><span v-if="locale=='en'" >My Consaltations</span> <span v-if="locale=='ar'" >استشاراتي</span></router-link>

                  </li>
                  <li>
                    <router-link to="/contact"><span v-if="locale=='en'" >Contact Us</span> <span v-if="locale=='ar'" >تواصل معنا</span></router-link>
                  </li>
                  <li>
                    <router-link to="/signup"><span v-if="locale=='en'" >Sign Up</span> <span v-if="locale=='ar'" >انشاء حساب</span></router-link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div class="col-lg-3 col-md-3">
            <nav  class="navbar navbar-light bg-light" style=" float: left;top: -4em; left: -2.1em;  display: none; "   id="mobile-toggle"

                  @click="showMobileMenu = !showMobileMenu">

                <button
                  class="navbar-toggler"
                  type="button"
                  data-mdb-toggle="collapse"
                  data-mdb-target="#navbarToggleExternalContent2"
                  aria-controls="navbarToggleExternalContent1"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <i class="fas fa-bars text-dark"></i>
                </button>


            </nav>
            <nav class="mean-nav">

              <ul style="display:none; height: 228px;     margin-right: 2rem; position: relative;"  :class="{ active: showMobileMenu }">
                <li>
                  <router-link to="/"> <span v-if="locale=='en'" >Home</span> <span v-if="locale=='ar'" >الرئيسيه</span></router-link>
                  <ul class="dropdown-menu-col-1">

                  </ul>
                </li>
                <li>
                  <router-link to="/about"> <span v-if="locale=='en'" >About</span> <span v-if="locale=='ar'" >عن الموقع</span></router-link>
                </li>
                <li>
                  <router-link to="/specialization"> <span v-if="locale=='en'" >Specializations</span> <span v-if="locale=='ar'" >التخصصات</span></router-link>
                </li>
                <li>
                  <router-link to="/doctor"><span v-if="locale=='en'" >Doctors</span> <span v-if="locale=='ar'" >الأطباء</span></router-link>

                </li>
                <li>
                  <router-link to="/myconsaltations"><span v-if="locale=='en'" >My Consaltations</span> <span v-if="locale=='ar'" >استشاراتي</span></router-link>

                </li>
                <li>
                  <router-link to="/contact"><span v-if="locale=='en'" >Contact Us</span> <span v-if="locale=='ar'" >تواصل معنا</span></router-link>
                </li>
                <li>
                  <router-link to="/signup"><span v-if="locale=='en'" >Sign Up</span> <span v-if="locale=='ar'" >انشاء حساب</span></router-link>
                </li>
              </ul>
            </nav>
            <div class="header-action-items-layout1" :style="'display:'+display">
              <ul>
                <li class="d-none d-xl-block">
                  <form id="top-search-form" class="header-search-dark">
                    <input type="text" class="search-input" placeholder="Search...." required=""  name="doctor-name" v-model="search_form.doctor_name" @keyup.enter="searchDoctor()" />
                    <button class="search-button">
                      <i class="flaticon-search"></i>
                    </button>
                  </form>
                </li>
                <li>
                  <router-link to="/doctor" class="action-items-primary-btn"><span v-if="locale=='en'" >Appointment</span> <span v-if="locale=='ar'" >احجز ميعاد</span><i class="fas fa-chevron-left"></i></router-link>
                </li>
              </ul>
            </div>
          </div>
        </div >
      </div>
    </div>
  </header>
  <!-- Header Area End Here -->

</template>
<script>
  import axios from 'axios'
  import swal2 from 'sweetalert2'
  import '../../assets/style.css'
  import '../../assets/img/favicon.png'
  import '../../assets/css/normalize.css'
  import '../../assets/css/main.css'
  import '../../assets/css/bootstrap.min.css'
  import '../../assets/css/animate.min.css'
  import '../../assets/css/fontawesome-all.min.css'
  import '../../assets/fonts/flaticon.css'
  import '../../assets/css/font/flaticon.css'
  import '../../assets/css/meanmenu.min.css'
  import '../../assets/css/magnific-popup.css'
  import '../../assets/vendor/OwlCarousel/owl.carousel.min.css'
  import '../../assets/vendor/OwlCarousel/owl.theme.default.min.css'
  import '../../assets/vendor/slider/css/nivo-slider.css'
  import '../../assets/css/bootstrap-rtl.css'
  import '../../assets/css/elements.css'


  export default {
    name:"AppHeader",
    props:{navData:Array},
    data(){
      return{
        page: 1,
        display: 'none',
        display_menu: 'none',
        showMobileMenu: false,
        search_form: {
          /*specialization_id:"",*/
          doctor_name: "",
          page:this.page,
          per_page:2
        },
      }
    },
    methods:{
      /*getLogo : async  function(){
      const response=await axios.get(this.$apiUrl+"navWebData" ,null)
    if(response.data.status==true){

      this.navData=response.data.data

          console.log(this.navData.logo_light)


    }else{
      swal2.fire("error" ,response.data.message,"error");

    }

  }*/
      searchDoctor:async function(){

        this.$router.push('/doctor/'+this.search_form.doctor_name)

      },
      onResize() {
        if (window.innerWidth > 960) {
          this.display = 'block'
          this.display_menu= "none"
        } else {
          this.display = 'none'
          this.display_menu= "block"
        }
      },
      togglemenu(){
        this.display_menu_bar= "bloc"
      }

    },
    mounted:function(){
      //  this.getLogo()
       this.onResize()
    //  window.addEventListener('resize',this.onResize)
    },

    created() {
      window.addEventListener('resize', this.onResize)
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.onResize)
    },


  }
</script>
<style>
  .active{
    display: block !important;
  }
  .mean-nav ul li{
    border-top: 1px solid#120d0d;
    border-bottom: 1px solid#0c0c0c;
    background: #ebebeb;
  }
  .mean-nav ul li a{
    font-weight: 700;
    background:#ebebeb none repeat scroll 0 0;
    color: #333333;
    display: block;
    font-size: 12px;
    margin: 0;
    padding: 1em 5%;
    text-decoration: none;
    text-transform: uppercase;
    width: 90%;
    font-weight: bold;
  }
  @media only screen and (max-width: 767px) {
     .rc-carousel  .carousel__viewport ol li {
         width: 50% !important;
       }
    #mobile-toggle{display: block !important}
  }
  @media only screen and (max-width: 575px) {
    .rc-carousel.carousel__viewport ol li {
      width: 50% !important;
    #mobile-toggle{display: block !important}

    }
    #mobile-toggle{display: block !important}
  }
  @media only screen and (max-width: 991px) {
      #mobile-toggle{
        display: none;
      }
#owl-nav2{
  padding-top: 9rem;
}
#owl-nav1{
  padding-top: 9rem;
}
  }
</style>
