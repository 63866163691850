<template>

  <!-- Inne Page Banner Area Start Here -->
  <section class="inner-page-banner bg-common inner-page-top-margin"  :style="{backgroundImage:'url(\'' +this.$attachUrl+'images/'+this.navData.doctors_banner+ '\')'}">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="breadcrumbs-area">
            <h1 v-if="locale=='en'">  About </h1><h1 v-else>عن الموقع </h1>
            <ul>
              <li>
                <a v-if="locale=='en'" href="#">Home</a>
                <a v-else href="#">الرئيسيه</a>
              </li>
              <li v-if="locale=='en'">About</li>
              <li v-else> عن الموقع</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Inne Page Banner Area End Here -->
  <!-- About Us Start Here -->
  <section class="about-wrap-layout5">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="about-box-layout13">
            <h2 v-if="locale=='ar'" class="item-title">{{navData.name_ar}}</h2>
            <h2 v-if="locale=='en'" class="item-title">{{navData.name_en}}</h2>
            <p  v-if="locale=='ar'" >{{navData.description_ar}}</p>
            <p  v-if="locale=='en'" >{{navData.description_en}}</p>
            <p  v-if="locale=='ar'" >{{navData.content_ar}}</p>
            <p  v-if="locale=='en'" >{{navData.content_en}}</p>
           <!-- <ul class="list-info">
              <li>Qualified Staff of Doctors</li>
              <li>Feel like you are at Home Services</li>
              <li>24x7 Emergency Services</li>
              <li>Easy and Affordable Billing</li>
            </ul>-->
          </div>
        </div>
        <div class="col-lg-6">
          <div class="about-box-layout14">
            <div class="item-video">
              <figure class="media"><oembed v-bind:src="navData.about_vedio_link"></oembed></figure> <figure class="media"><iframe v-bind:src="navData.about_vedio_link"></iframe></figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- About Us Area End Here -->

  <!-- Department Area Start Here -->
  <section class="departments-wrap-layout1 bg-light-accent100">
    <div class="container menu-list-wrapper">
      <div class="section-heading heading-dark text-center heading-layout1">
        <h2>
          <span v-if="locale=='en'">Our Departments</span>
          <span v-if="locale=='ar'">التخصصات</span>
        </h2>
      </div>
      <div class="row menu-list">
        <div v-for="(special,index) in specializeData" class="col-xl-3 col-lg-6 col-md-6 col-12 menu-item">
          <div  v-if="index < 4"  class="departments-box-layout1">
            <div class="item-img">
              <img v-bind:src="$attachUrl+'images/'+special.img"   @error='$event.target.src=$attachUrl+"images/specializations/default.jpeg"' alt="department" class="img-fluid">
              <div class="item-btn-wrap">

                <router-link to="/doctor?specialization_id={{special.id}}" class="item-btn">   <span v-if="locale=='en'">Details</span>
                  <span v-if="locale=='ar'">التفاصيل</span></router-link>
              </div>
            </div>
            <div class="item-content">
              <h3 class="item-title">
                <router-link to="/doctor?specialization_id={{special.id}}" class="item-btn">   <span v-if="locale=='en'">{{special.name_en}}</span>
                  <span v-if="locale=='ar'">{{special.name_ar}}</span></router-link>
              </h3>
              <ul class="department-info">
                <!--   <li>
                    <i class="flaticon-doctor"></i>
                   <span>06</span>Specialist Docotrs</li>-->
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="loadmore loadmore-layout1 margin-t-20" data-count="4">

        <router-link to="/specialization" class="item-btn">   <span v-if="locale=='en'">More Departments</span>
          <span v-if="locale=='ar'">تخصصات أخري</span></router-link>
      </div>
    </div>
  </section>
  <!-- Department Area End Here -->
</template>
<script>
  // @ is an alias to /src

  export default {
    name: 'AboutView',
    props:{navData:Array,specializeData:Array},
  }
</script>
